import React from 'react'

import './styles.scss'

import lock from './images/lock.svg'
import lockMobile from './images/lock-mobile.svg'
import instagram from './images/instagram.svg'
import twitter from './images/twitter.svg'
import twitterGreen from './images/twitter-green.svg'
import linkedin from './images/linkedin.svg'
import linkedinGreen from './images/linkedin-green.svg'
import facebook from './images/facebook.svg'

import { config } from '../../constants'
import moment from 'moment-timezone'

export function AppFooter({
	className = '',
	children
}) {

	return (
		<footer className={`${className}`}>
			<div className="footer-above">
				<div className="footer-left">
					<img className="footer-lock" src={lock} alt="" />
					<img className="footer-lock-mobile" src={lockMobile} alt="" />
					<ul>
						<li><a target="_blank" rel="noreferrer" href="https://lockrmail.com/support/">Support</a></li>
						<li><a target="_blank" rel="noreferrer" href="https://lockrmail.com/product-releases/">Releases</a></li>
						<li><a target="_blank" rel="noreferrer" href="https://lockrmail.com/wall-of-love/">Wall of Love</a></li>
						<li><a target="_blank" rel="noreferrer" href="https://loc.kr/careers/">Careers</a></li>
					</ul>
				</div>
				<div className="footer-right">
					<div className="footer-right-social">
						<li className="footer-right-social-item">
							<a target="_blank" rel="noreferrer" href="https://twitter.com/lockr_digital">
								<img src={twitter} alt="" />
								<img src={twitterGreen} alt="" />
							</a>
						</li>
						<li className="footer-right-social-item">
							<a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/digital-lockr/">
								<img src={linkedin} alt="" />
								<img src={linkedinGreen} alt="" />
							</a>
						</li>
					</div>
				</div>
			</div>
			<div className="footer-legal">
				<li><a href={config.STATUS_URL}>Application Status</a></li>
				<li><a href={config.PRIVACY_URL}>Privacy Policy</a></li>
				<li><a href={config.TERMS_URL}>Terms of Service</a></li>
				<li>© {moment().format('YYYY')} <a target="_blank" rel="noreferrer" href="https://loc.kr">lockr</a></li>
			</div>
			{children}
		</footer>
	)
}