import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'

import './styles.scss'
import iconUnchecked from 'assets/images/checkbox-8B8B8B.svg'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconArrowDown from 'assets/images/arrow_down.svg'
import iconAddMore from 'assets/images/plus-8B8B8B.svg'
import crossIcon from 'assets/images/cross/white.svg';
import resetGrey from 'assets/images/reset-grey.svg'

import {
	Checkbox,
	Select, SelectDropDown, SelectGroup, SelectOption,
	SearchField,
	InfoAlert,
} from 'components'
import { MobileSenderSettings } from '../mobile-settings'

import {
	useAlert,
	useAuth,
	useSenders, Sender,
	SenderGroup, useSenderGroup,
	Filter,
	useDigests,
} from 'core'
import { ArrayHelper } from 'utils'
import { routes, redirectTo } from 'routes'
import { errorMessages } from 'constants/error'
import { MobileDashboardFilterModal } from './mobile-dashboard-filter-modal'

const OVERALL_FILTER_STATUS = {
	'DESTINATION': 'DESTINATION',
	'DIGEST': 'DIGEST',
	'GROUP': 'GROUP',
	'STATUS': 'STATUS',
}

function FilterChip({ item, onClose, index, hideClose, extraClass }) {
	return (
		<div style={{}} className={`filter-chip-parent ${extraClass}`}>
			<label className='filter-chip-text'>{item.label}</label>
			{!hideClose && <img onClick={() => onClose(item)} alt='cross' style={{ marginLeft: 10, cursor: 'pointer' }} src={crossIcon} />}
		</div>
	)
}

function SelectedFilter({
	selectedFilters,
	onClose
}) {
	if (selectedFilters.length) {
		return (
			<div style={{ display: 'flex', paddingTop: 16, flexWrap: 'wrap', gap: 8 }}>
				{selectedFilters.map((item, index) => {
					return (
						<FilterChip hideClose={item.label === 'All Senders'} onClose={onClose} index={index} item={item} extraClass={"dashboard"} />
					)
				})}
			</div>
		);
	}
	//No Filters render chip for all senders
	return (
		<div style={{ display: 'flex', paddingTop: '16px' }}>
			<FilterChip hideClose item={{ label: 'All Senders' }} onClose={() => { }} extraClass={"dashboard"} />
		</div>
	)

}

export function DashboardTableHeader({
	className = "",
	responsive = {},

	selectedSenders = [],
	onSelectAll = (select) => { },

	onFilter = (senders) => { },

	searchText = "",
	onSearchText = (value) => { },

	// View Options
	senderStatus,
	onOptionSenderStatus = (value) => { },
	sortBy,
	onOptionSortBy = (value) => { },
	pageSize,
	onOptionPageSize = (value) => { },
	optionSenderGroupId,
	onOptionSenderGroup = (senderGroupId) => { },
	onGroupFiltersSelected = (selectedGroups) => { },
	onDigestFiltersSelected = (selectedDigestFilters) => { },
	defaultSelectedDigests = [],
	selectedConnectedEmailId,
	onSelectConnectedEmailId = (selectedConnectedEmailId) => { },
}) {
	const { breakpoint = 992 } = responsive

	const navigate = useNavigate()
	const { width: windowWidth } = useWindowSize()

	const { setError, setAlert, resetAlert } = useAlert()
	const { currentUser } = useAuth()
	const { refreshSenders } = useSenders()
	const { senderGroups } = useSenderGroup()
	const { myDigests } = useDigests();

	const [isBatchEnabled, setBatchEnabled] = React.useState(false)
	const [isBatchMenuOpen, setBatchMenuOpen] = React.useState(false)
	const [hasFilters, setHasFilters] = React.useState(false)
	const [filterEnabled, setFilterEnabled] = React.useState(false)
	const [noFilteredSenders, setNoFilteredSenders] = React.useState([])
	const [isDeleteFilters, setDeleteFilters] = React.useState(false)

	const [connectedEmailId, setConnectedEmailId] = React.useState(null)
	const [connectedEmails, setConnectedEmails] = React.useState([])
	const [connectedVerifiedEmails, setConnectedVerifiedEmails] = React.useState([])
	const [senderGroupId, setSenderGroupId] = React.useState(null)
	const [conflictedSenderGroupId, setConflictedSenderGroupId] = React.useState(null)

	const [selectedGroupFilters, setSelectedGroupFilters] = React.useState([]);
	const [selectedDigestFilters, setSelectedDigestFilters] = React.useState(defaultSelectedDigests.length ? defaultSelectedDigests : []);
	const [overallFilters, setOverallFilters] = React.useState(defaultSelectedDigests.length ? [{
		id: defaultSelectedDigests[0].id,
		label: defaultSelectedDigests[0].name,
		type: OVERALL_FILTER_STATUS.DIGEST
	}] : []);

	const [digestSearch, setDigestSearch] = React.useState('');
	const [groupSearch, setGroupSearch] = React.useState('');

	const selectSortRef = React.useRef({ value: undefined });

	const [showMobileDashboardFilters, setMobileDashboardFilters] = useState(false);

	const { width } = useWindowSize();

	React.useEffect(() => {
		onDigestFiltersSelected(selectedDigestFilters)
	}, [selectedDigestFilters]);

	React.useEffect(() => {
		onGroupFiltersSelected(selectedGroupFilters)
	}, [selectedGroupFilters]);

	React.useEffect(() => {
		if (currentUser) {
			setConnectedEmails(currentUser.connectedEmails)
			setConnectedVerifiedEmails(currentUser.emails.filter(x => x.isVerified))
		}
	}, [currentUser])

	React.useEffect(() => {
		setBatchEnabled(ArrayHelper.isValid(selectedSenders))
		setHasFilters(ArrayHelper.isValid(selectedSenders.filter((sender) => sender.hasFilter)))
		setFilterEnabled(calcFilterEnabled())
		setNoFilteredSenders(selectedSenders.filter((sender) => !sender.hasFilter))

		setConnectedEmailId(calcConnectedEmailId())
		setSenderGroupId(calcSenderGroupId())
	}, [selectedSenders])

	function deviceName() {
		if (windowWidth >= 992) return 'desktop'
		if (windowWidth >= 577) return 'tablet'
		return 'mobile'
	}

	function calcConnectedEmailId() {
		let result = null
		for (let sender of selectedSenders) {
			if (result === null) { result = sender.connectedEmailId }
			else if (result !== sender.connectedEmailId) { return null }
		}
		return result
	}

	function calcFilterEnabled() {
		const filteredSenders = selectedSenders.filter((sender) => sender.hasFilter)
		return filteredSenders.length === 0 || filteredSenders.length === selectedSenders.length
	}

	function calcSenderGroupId() {
		let result = null
		for (let sender of selectedSenders) {
			if (result === null) { result = sender.senderGroupId }
			else if (result !== sender.senderGroupId) { return null }
		}
		return result
	}

	function handleRefreshSenders(senders = null) {
		const senderIds = (senders || selectedSenders).map(sender => sender.id)
		refreshSenders(senderIds)
	}

	async function handleBlock(block) {
		const anySenderHasFilter = selectedSenders.some((sender) => sender.hasFilter);
		if (anySenderHasFilter) {
			setAlert({
				message: errorMessages.FILTER_OVERRIDE_MESSAGES_MULTIPLE,
				buttons: [
					{
						title: 'Confirm',
						type: 'positive',
						onClick: async () => {
							const { error, results } = await Sender.block(selectedSenders, block)
							if (error) {
								setError(error)
								return
							}

							if (ArrayHelper.isValid(results)) {
								handleRefreshSenders(results)
							}


						}
					}, {
						title: 'Cancel',
						type: 'negative',
						onClick: () => {
							resetAlert()

						}
					},]
			})
			return;
		}
		const { error, results } = await Sender.block(selectedSenders, block)
		if (error) {
			setError(error)
			return
		}
		if (error) {
			setError(error)
			return
		}

		if (ArrayHelper.isValid(results)) {
			handleRefreshSenders(results)
		}
	}

	async function deleteFilters() {
		const senders = selectedSenders.filter((sender) => sender.hasFilter)
		for (let sender of senders) {
			await Filter.delete({ id: sender.filterId })
		}

		if (ArrayHelper.isValid(senders)) {
			handleRefreshSenders(senders)
		}
	}

	function handleMore() {
		if (windowWidth < breakpoint) setBatchMenuOpen(true)
		else { }
	}

	async function handleSetConnectedEmail(connectedEmailId) {
		if (selectedSenders.length <= 0) { return }

		const { error } = await Sender.setConnectedEmail(selectedSenders, connectedEmailId)
		if (error) {
			setError(error)
			return
		}

		handleRefreshSenders()
	}

	function handleMoreFilter() {
		const filteredSenders = selectedSenders.filter((sender) => sender.hasFilter)
		if (ArrayHelper.isValid(filteredSenders)) {
			setDeleteFilters(true)
		} else {
			onFilter(selectedSenders)
		}
	}

	async function setSenderGroup(senderGroupId) {
		const senderIds = selectedSenders.map(e => e.id)
		const { error } = await SenderGroup.addSender(senderGroupId, senderIds)
		if (error) {
			setError(error)
			return
		}

		setConflictedSenderGroupId(null)

		handleRefreshSenders()
	}

	async function handleSetSenderGroup(senderGroupId) {
		if (Sender.hasConflictedSenderGroup(selectedSenders)) {
			setConflictedSenderGroupId(senderGroupId)
		} else {
			await setSenderGroup(senderGroupId)
		}
	}

	async function handleSenderGroupAddNew() {
		const { error, result } = await SenderGroup.create(null, selectedSenders)
		if (error) {
			setError(error)
			return
		}

		navigate(redirectTo({
			route: routes.Account,
			category: routes.Category.Account.Groups,
			searches: { senderGroupId: result.id }
		}))
	}

	function handleResetViewOptions() {
		onOptionSenderStatus('all')
		onOptionSortBy('recentAt')
		onOptionPageSize(25)
		onOptionSenderGroup(null)
		setOverallFilters([]);
		setSelectedDigestFilters([]);
		setSelectedGroupFilters([]);
		onSelectConnectedEmailId(null)
	}

	function onDigestFilterSelected(digestItem) {
		const id = digestItem.id;
		const existingDigests = [...selectedDigestFilters];
		const existingOverallFilters = [...overallFilters];
		const overAllFilterIndex = overallFilters.findIndex(item => {
			if (item.id === id) {
				return 1;
			}
			return 0
		})
		const digestIndex = selectedDigestFilters.findIndex(item => {
			if (item.id === id) {
				return 1;
			}
			return 0;
		})
		if (digestIndex >= 0) {
			existingDigests.splice(digestIndex, 1);
			existingOverallFilters.splice(overAllFilterIndex, 1);
			setOverallFilters(existingOverallFilters);
			setSelectedDigestFilters(existingDigests);
			return;
		}
		existingDigests.push(digestItem);
		existingOverallFilters.push({
			id: digestItem.id,
			label: digestItem.name,
			type: OVERALL_FILTER_STATUS.DIGEST
		});
		setOverallFilters(existingOverallFilters);
		setSelectedDigestFilters(existingDigests);
	}

	function checkIfDigestExistsInFilter(digestItem) {
		const id = digestItem.id;
		const filterDigestIndex = selectedDigestFilters.findIndex(item => {
			if (item.id === id) {
				return 1;
			}
			return 0;
		})
		return filterDigestIndex >= 0;
	}

	function onAllDigestFilterSelected() {
		setSelectedDigestFilters([]);
		const updatedOverAllStatus = overallFilters.filter(item => item.type !== OVERALL_FILTER_STATUS.DIGEST);
		setOverallFilters(updatedOverAllStatus);
	}

	function onGroupFilterSelected(senderGroup) {
		const id = senderGroup.id;
		const existingGroups = [...selectedGroupFilters];
		const existingOverallFilters = [...overallFilters]
		const overallFilterIndex = overallFilters.findIndex(item => {
			if (item.id === id) {
				return 1;
			}
			return 0;
		})
		const filterGroupIndex = selectedGroupFilters.findIndex(item => {
			if (item.id === id) {
				return 1;
			}
			return 0;
		});
		if (filterGroupIndex >= 0) {
			existingGroups.splice(filterGroupIndex, 1);
			existingOverallFilters.splice(overallFilterIndex, 1);
			setOverallFilters(existingOverallFilters);
			setSelectedGroupFilters(existingGroups);
			return;
		}
		existingGroups.push(senderGroup);
		existingOverallFilters.push({
			id: senderGroup.id,
			label: senderGroup.name,
			type: OVERALL_FILTER_STATUS.GROUP
		})
		setOverallFilters(existingOverallFilters);
		setSelectedGroupFilters(existingGroups);
	}

	function checkIfGroupExistsInFilter(senderGroup) {
		const id = senderGroup.id;
		const filterGroupIndex = selectedGroupFilters.findIndex(item => {
			if (item.id === id) {
				return 1;
			}
			return 0;
		})
		return filterGroupIndex >= 0;
	}

	function onGroupAllFilterSelected() {
		setSelectedGroupFilters([]);
		const updatedOverAllStatus = overallFilters.filter(item => item.type !== OVERALL_FILTER_STATUS.GROUP);
		setOverallFilters(updatedOverAllStatus);
	}

	function onRemoveFilterItem(filterItem) {
		const existingOverallFilters = [...overallFilters];
		const existingFilterIndex = existingOverallFilters.findIndex(item => {
			if (item.id === filterItem.id) {
				return 1
			}
			return 0;
		})
		if (existingFilterIndex >= 0) {
			existingOverallFilters.splice(existingFilterIndex, 1);
			setOverallFilters(existingOverallFilters);
			if (filterItem.type === OVERALL_FILTER_STATUS.STATUS) {
				onOptionSenderStatus('all');
				return;
			} else if (filterItem.type === OVERALL_FILTER_STATUS.DESTINATION) {
				onSelectConnectedEmailId(filterItem.id)
			}
			const existingGroupFilters = filterItem.type === OVERALL_FILTER_STATUS.GROUP ? [...selectedGroupFilters] : [...selectedDigestFilters];
			const existingGroupIndex = existingGroupFilters.findIndex(groupItem => {
				if (groupItem.id === filterItem.id) {
					return 1;
				}
				return 0;
			})
			if (existingGroupIndex >= 0) {
				existingGroupFilters.splice(existingGroupIndex, 1);
				if (filterItem.type === OVERALL_FILTER_STATUS.DIGEST) {
					setSelectedDigestFilters(existingGroupFilters);
				} else {
					setSelectedGroupFilters(existingGroupFilters);
				}
			}

		}
	}

	function onSenderStatusSelected(value, label) {
		const existingOverallFilters = [...overallFilters];
		const exisitngOverallFilterIndex = existingOverallFilters.findIndex(item => {
			if (item.type === OVERALL_FILTER_STATUS.STATUS) {
				return 1;
			}
			return 0;
		})
		if (exisitngOverallFilterIndex >= 0) {
			existingOverallFilters[exisitngOverallFilterIndex] = {
				type: OVERALL_FILTER_STATUS.STATUS,
				label,
				id: value,
			}
		} else {
			existingOverallFilters.push({
				type: OVERALL_FILTER_STATUS.STATUS,
				id: value,
				label
			});
		}
		setOverallFilters(existingOverallFilters);
		onOptionSenderStatus(value);
	}

	function checkIfFilterDisabled(senderGroup) {
		const id = senderGroup.id;
		if (selectedGroupFilters.length >= 1 && selectedGroupFilters[0].id !== id) {
			return true;
		}
		return false;
	}

	function handleDestinationFilter(connectedEmail) {
		let existingOverallFilters = [...overallFilters];
		if (connectedEmail === null) {
			// Remove all items of type DESTINATION
			existingOverallFilters = existingOverallFilters.filter(item => item.type !== OVERALL_FILTER_STATUS.DESTINATION);
		} else {
			// Check if the item already exists in the array
			const itemIndex = existingOverallFilters.findIndex(item => item.id === connectedEmail.id);

			if (itemIndex === -1) {
				// If the item is not found, add the new item
				existingOverallFilters.push({
					id: connectedEmail.id,
					label: connectedEmail.email,
					type: OVERALL_FILTER_STATUS.DESTINATION
				});
			} else {
				// If the item is found, remove it
				existingOverallFilters.splice(itemIndex, 1);
			}
		}

		setOverallFilters(existingOverallFilters);
		onSelectConnectedEmailId(connectedEmail ? connectedEmail.id : connectedEmail)
	}

	return (
		<>
			<div className={`${className} dashboard-table-header ${deviceName()}`}>
				<div className="checkbox-container">
					<Checkbox
						iconUnchecked={iconUnchecked}
						iconChecked={iconChecked}
						checked={isBatchEnabled}
						onChange={(value) => onSelectAll(value)} />
					<div className="arrow-container">
						<img src={iconArrowDown} alt="" />
						<SelectDropDown>
							<SelectOption selectable={false} onClick={() => onSelectAll(true)}>Select All</SelectOption>
							<SelectOption selectable={false} onClick={() => onSelectAll(false)}>Deselect All</SelectOption>
						</SelectDropDown>
					</div>
				</div>
				<div className={`batch-container ${isBatchEnabled && 'active'}`}>
					<button className="btn-batch allow" onClick={() => handleBlock(false)}>
						<svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M17.059 5.42564L7.32239 19.3349L1.45749 14.2556" stroke="#888888" strokeLinecap="round" />
							<path d="M17.059 5.42564L7.32239 19.3349L1.45749 14.2556" stroke="#888888" strokeLinecap="round" />
						</svg>
					</button>
					<button className="btn-batch block" onClick={() => handleBlock(true)}>
						<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
							<line x1="0.5" y1="-0.5" x2="22.1272" y2="-0.5" transform="matrix(0.707114 0.7071 -0.707114 0.7071 1 1)" stroke="#888888" strokeLinecap="round" />
							<line x1="0.5" y1="-0.5" x2="22.1272" y2="-0.5" transform="matrix(0.707114 0.7071 -0.707114 0.7071 1 1)" stroke="#888888" strokeLinecap="round" />
							<line x1="0.5" y1="-0.5" x2="22.1272" y2="-0.5" transform="matrix(0.707114 -0.7071 0.707114 0.7071 1 17)" stroke="#888888" strokeLinecap="round" />
							<line x1="0.5" y1="-0.5" x2="22.1272" y2="-0.5" transform="matrix(0.707114 -0.7071 0.707114 0.7071 1 17)" stroke="#888888" strokeLinecap="round" />
						</svg>
					</button>
					{!hasFilters &&
						<button className="btn-batch filter" onClick={() => onFilter(noFilteredSenders)}>
							<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<line x1="0.5" y1="2.16937" x2="17.5" y2="2.16937" stroke="#8B8B8B" strokeLinecap="round" strokeLinejoin="round" />
								<line x1="0.5" y1="7.06445" x2="17.5" y2="7.06445" stroke="#8B8B8B" strokeLinecap="round" strokeLinejoin="round" />
								<line x1="0.5" y1="11.9596" x2="17.5" y2="11.9596" stroke="#8B8B8B" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M6.26947 1.95804C6.26947 2.75336 5.60794 3.41608 4.76947 3.41608C3.931 3.41608 3.26947 2.75336 3.26947 1.95804C3.26947 1.16273 3.931 0.5 4.76947 0.5C5.60794 0.5 6.26947 1.16273 6.26947 1.95804Z" fill="#1D1D1D" stroke="#929292" />
								<path d="M7.43164 12.0427C7.43164 12.838 6.77011 13.5008 5.93164 13.5008C5.09317 13.5008 4.43164 12.838 4.43164 12.0427C4.43164 11.2474 5.09317 10.5847 5.93164 10.5847C6.77011 10.5847 7.43164 11.2474 7.43164 12.0427Z" fill="#1D1D1D" stroke="#929292" />
								<path d="M12.2773 6.85315C12.2773 7.64847 11.6158 8.31119 10.7773 8.31119C9.93887 8.31119 9.27734 7.64847 9.27734 6.85315C9.27734 6.05784 9.93887 5.39511 10.7773 5.39511C11.6158 5.39511 12.2773 6.05784 12.2773 6.85315Z" fill="#1D1D1D" stroke="#929292" />
							</svg>
						</button>
					}
					<div className="btn-batch more" onClick={() => handleMore()}>
						<svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="18" cy="2" r="2" transform="rotate(90 18 2)" fill="#8B8B8B" />
							<circle cx="10" cy="2" r="2" transform="rotate(90 10 2)" fill="#8B8B8B" />
							<circle cx="2" cy="2" r="2" transform="rotate(90 2 2)" fill="#8B8B8B" />
						</svg>
						{windowWidth >= breakpoint ?
							<SelectDropDown type="horizontal">
								<SelectGroup label="Change Email Destination">
									{connectedEmails.map((connectedEmail, index) =>
										<SelectOption
											key={index}
											selected={connectedEmailId === connectedEmail.id}
											onClick={() => handleSetConnectedEmail(connectedEmail.id)}>
											{connectedEmail.email}
										</SelectOption>
									)}
								</SelectGroup>
								{filterEnabled &&
									<SelectOption
										selectable={false}
										onClick={() => handleMoreFilter()}>
										{hasFilters ? 'Delete Filter' : 'Create Filter'}
									</SelectOption>
								}
								<SelectGroup label="Add to Group">
									{(senderGroups || []).map((senderGroup, index) =>
										<SelectOption
											key={index}
											selected={senderGroupId === senderGroup.id}
											onClick={() => handleSetSenderGroup(senderGroup.id)}>
											{senderGroup.name}
										</SelectOption>
									)}
									<SelectOption className="add-more" selectable={false} onClick={() => handleSenderGroupAddNew()}>
										<img src={iconAddMore} alt="" loading="lazy" />
										<span>Add new</span>
									</SelectOption>
								</SelectGroup>
							</SelectDropDown>
							:
							<>
								{isBatchMenuOpen &&
									<MobileSenderSettings
										senders={selectedSenders}
										onBlock={(block) => handleBlock(block)}
										onClose={(updated) => {
											setBatchMenuOpen(false)
											if (updated) handleRefreshSenders()
										}} />
								}
							</>
						}

					</div>
				</div>
				<div className='filter-parent'>
					<SearchField
						minimized={windowWidth <= 576}
						value={searchText}
						onChange={(value) => onSearchText(value)} />
					<div>
						<Select
							minimized={width <= 576}
							ref={e => selectSortRef.current.value = e}
							className={'sort-filter'}
							label={'Sort By'}
							dropdownHeight={120}
						>
							<SelectGroup label="Sort By">
								<SelectOption selected={sortBy === 'recentAt'} onClick={() => onOptionSortBy('recentAt')}>Most recent</SelectOption>
								<SelectOption selected={sortBy === 'mailCount'} onClick={() => onOptionSortBy('mailCount')}>Most emails</SelectOption>
							</SelectGroup>
						</Select>
					</div>
				</div>
			</div>
			<div onClick={() => {
				setMobileDashboardFilters(true);
			}} className='filter-results-container'>
				Filter Results
			</div>
			<div className='detailed-filter-container'>
				<div className='filter-section-chips'>
					<div className='your-filter-section'>
						<span className='your-filter-text'>Your filters</span>
						<span className='reset-filter-text' onClick={handleResetViewOptions}> <img src={resetGrey} />
							Reset</span>
					</div>
					<SelectedFilter onClose={(filterItem) => {
						onRemoveFilterItem(filterItem);
					}} selectedFilters={overallFilters} />
				</div>
				<div className='destination-email-container'>
					<div className='filter-text-count-container'>
						<span className='your-filter-text'>Destination Email</span>
						<div className='status-text-count-container'>
						</div>
					</div>
					<Select
						inputPlaceholder={!selectedConnectedEmailId ? 'All' : 'Select'}
						className={'sender-status-select'}
					>
						<SelectGroup>
							<SelectOption
								newCheckbox={true}
								selected={selectedConnectedEmailId === null}
								onClick={() => { handleDestinationFilter(null); }}
							>
								View All
							</SelectOption>

							{connectedVerifiedEmails.map((connectedEmail, index) => (
								<SelectOption
									key={connectedEmail.id}
									newCheckbox={true}
									selected={selectedConnectedEmailId?.includes(connectedEmail.id)}
									onClick={() => { handleDestinationFilter(connectedEmail); }}
								>
									{connectedEmail.email}
								</SelectOption>
							))}

						</SelectGroup>
					</Select>
				</div>
				<div className='sender-status-container'>
					<div className='filter-text-count-container'>
						<span className='your-filter-text'>Sender Status</span>
						<div className='status-text-count-container'>
						</div>
					</div>
					<Select
						inputPlaceholder={'Select'}
						className={'sender-status-select'}
					>
						<SelectGroup>
							<SelectOption newCheckbox={true} selected={senderStatus == 'all'} onClick={() => { onSenderStatusSelected('all', 'All Senders') }}>All</SelectOption>
							<SelectOption newCheckbox={true} selected={senderStatus == 'blocked'} onClick={() => onSenderStatusSelected('blocked', 'Blocked')}>Blocked</SelectOption>
							<SelectOption newCheckbox={true} selected={senderStatus == 'allowed'} onClick={() => onSenderStatusSelected('allowed', 'Allowed')}>Allowed</SelectOption>
							<SelectOption newCheckbox={true} selected={senderStatus == 'filtered'} onClick={() => onSenderStatusSelected('filtered', 'Filtered')}>Filtered</SelectOption>
							<SelectOption newCheckbox={true} selected={senderStatus == 'unclassified'} onClick={() => onSenderStatusSelected('unclassified', 'Unclassified')}>Unclassified</SelectOption>
						</SelectGroup>
					</Select>
				</div>
				<div className='group-filter-container'>
					<div className='filter-text-count-container'>
						<span className='your-filter-text'>Groups</span>
						<div className='text-count-container'>
							<span className='filter-text-count'>{senderGroups.length}</span>
						</div>
					</div>
					<Select
						className={'sender-status-select'}
						inputPlaceholder={'Select'}
						supportSearch={true}
						onSearchValueChange={val => setGroupSearch(val)}
					>
						<SelectGroup>
							<SelectOption newCheckbox={true} selected={selectedGroupFilters.length === 0} onClick={() => onGroupAllFilterSelected()}>All</SelectOption>
							{!!senderGroups?.length && senderGroups.map((senderGroup, index) => {
								if (senderGroup.name.toLowerCase().includes(groupSearch.toLowerCase())) {
									return (
										<SelectOption
											disabled={checkIfFilterDisabled(senderGroup)}
											newCheckbox={true}
											key={index}
											selected={checkIfGroupExistsInFilter(senderGroup)}
											onClick={() => onGroupFilterSelected(senderGroup)}
										>{senderGroup.name}</SelectOption>
									)
								}
							})}
						</SelectGroup>
					</Select>
				</div>
				<div className='digest-filter-container'>
					<div className='filter-text-count-container'>
						<span className='your-filter-text'>Digests</span>
						<div className='text-count-container'>
							<span className='filter-text-count'>{myDigests?.length ?? '0'}</span>
						</div>
					</div>
					<Select
						className={'sender-status-select'}
						inputPlaceholder={'Select'}
						supportSearch={true}
						onSearchValueChange={val => setDigestSearch(val)}
						newCheckbox={true}
					>
						<SelectGroup>
							<SelectOption newCheckbox={true} selected={selectedDigestFilters.length === 0} onClick={() => onAllDigestFilterSelected()}>All</SelectOption>
							{myDigests?.length && myDigests.map((digestItem, index) => {
								if (digestItem.name.toLowerCase().includes(digestSearch.toLowerCase())) {
									return (
										<SelectOption
											newCheckbox={true}
											key={index}
											selected={checkIfDigestExistsInFilter(digestItem)}
											onClick={() => { onDigestFilterSelected(digestItem) }}
										>{digestItem.name}</SelectOption>
									)
								}
							})}
						</SelectGroup>
					</Select>
				</div>
			</div>
			{isDeleteFilters &&
				<InfoAlert
					message="This action will remove all filters enabled for the selected Senders. Click to confirm."
					buttons={[
						{
							title: 'Confirm',
							type: 'positive',
							onClick: () => {
								deleteFilters()
								setDeleteFilters(false)
							}
						}, {
							title: 'Cancel',
							type: 'negative',
							onClick: () => setDeleteFilters(false)
						}]}
					onClose={() => setDeleteFilters(false)} />
			}
			{conflictedSenderGroupId &&
				<InfoAlert
					message="Assigning these Senders will override any prior Group associations. Senders are only able to be in a single Group. Click confirm to continue."
					buttons={[
						{ type: 'positive', title: 'Confirm', onClick: () => setSenderGroup(conflictedSenderGroupId) },
						{ type: 'negative', title: 'Cancel', onClick: () => setConflictedSenderGroupId(null) }
					]}
					onClose={() => setConflictedSenderGroupId(null)}
				/>
			}
			{showMobileDashboardFilters && <MobileDashboardFilterModal
				onClose={() => { setMobileDashboardFilters(false) }}
				senderStatus={senderStatus}
				onOptionSenderStatus={onOptionSenderStatus}
				senderGroups={senderGroups}
				groupSearch={groupSearch}
				checkIfGroupExistsInFilter={checkIfGroupExistsInFilter}
				onGroupFilterSelected={onGroupFilterSelected}
				selectedGroupFilters={selectedGroupFilters}
				checkIfDigestExistsInFilter={checkIfDigestExistsInFilter}
				digestSearch={digestSearch}
				myDigests={myDigests}
				onGroupAllFilterSelected={onGroupAllFilterSelected}
				handleResetViewOptions={handleResetViewOptions}
				onDigestFilterSelected={onDigestFilterSelected}
				selectedDigestFilters={selectedDigestFilters}
				onSenderStatusSelected={onSenderStatusSelected}
				checkIfFilterDisabled={checkIfFilterDisabled}
				selectedFilterRenderer={() => <SelectedFilter onClose={(filterItem) => {
					onRemoveFilterItem(filterItem);
				}} selectedFilters={overallFilters}
				/>}
				onSelectConnectedEmailId={id => handleDestinationFilter(id)}
				selectedConnectedEmailId={selectedConnectedEmailId}
				connectedEmails={connectedVerifiedEmails}

			/>}
		</>
	)
}