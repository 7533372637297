import React from 'react'

import './styles.scss'
import iconMail from 'assets/images/email-white.svg'
import iconFilter from 'assets/images/filter-FFFFFF.svg'
import iconGroup from 'assets/images/group-FFFFFF.svg'
import iconDomainSetting from 'assets/images/disable-white.svg'
import iconDigest from 'assets/images/digest-FFFFFF.svg'
import iconAddMore from 'assets/images/plus-8B8B8B.svg'

import { BlueButton, Icon, FilterModal } from 'components'
import { MailModal } from 'scenes/dashboard/mail-modal'
import { AddDomainModal } from 'scenes/account/domains/add-new-modal'
import { DomainSettingsAlert } from 'scenes/account/domains/domain-settings-modal'


import {
	useAlert, useAuth,
	useSenders, Sender, Filter,
	useSenderGroup,
	Segment,
	useDigests,
	Digest,
} from 'core'
import {
	DOMHelper, ArrayHelper, DomainHelper
} from 'utils'
import { useNavigate } from 'react-router-dom'
import { redirectTo, routeToMe, routes } from 'routes'
import { errorMessages } from 'constants/error'

const PageIndex = Object.freeze({
	Default: 0,
	DestinationEmail: 1,
	SenderGroup: 2,
	Digests: 3
})

/**
 * @typedef {{senders: Sender[], onBlock: *, onClose: *}} Props
 * @param {Props} param0
 */
export function MobileSenderSettings({
	senders: propsSenders,
	onBlock = (block) => { },
	onClose = (updated) => { }
}) {
	const navigate = useNavigate()
	const { setError, setAlert, resetAlert } = useAlert()
	const { currentUser } = useAuth()
	const { refreshSenders } = useSenders()
	const { senderGroups } = useSenderGroup()
	const { myDigests } = useDigests();

	const [connectedEmails, setConnectedEmails] = React.useState([])
	React.useEffect(() => {
		setConnectedEmails(currentUser?.connectedEmails || [])
	}, [currentUser])

	const [senders, setSenders] = React.useState(propsSenders)
	React.useEffect(() => { setSenders(propsSenders) }, [propsSenders])

	React.useEffect(() => {
		DOMHelper.enableScroll(false)
		return () => {
			DOMHelper.enableScroll(true)
		}
	}, [])

	const [pageIndex, setPageIndex] = React.useState(PageIndex.Default)

	const [senderListCollapsed, setSenderListCollapsed] = React.useState(false)
	React.useEffect(() => { setSenderListCollapsed(senders.length > 2) }, [senders])

	const [blocked, setBlocked] = React.useState(false)
	const [mailCount, setMailCount] = React.useState(0)
	const [connectedEmail, setConnectedEmail] = React.useState(null)
	const [senderGroup, setSenderGroup] = React.useState(null)
	const [selectedDigests, setSelectedDigests] = React.useState([]);
	const [filter, setFilter] = React.useState(undefined)
	React.useEffect(() => {
		setMailCount(calcMailCount())
		setConnectedEmail(calcConnectedEmail())

		loadFilter()
	}, [senders])
	React.useEffect(() => {
		setSenderGroup(calcSenderGroup())
		calcDigests();
	}, [senders, senderGroups])

	const [isUpdated, setUpdated] = React.useState(false)

	const [mailModal, setMailModal] = React.useState(false)
	const [filterModal, setFilterModal] = React.useState(false)
	const [domainSettingsModal, setDomainSettingsModal] = React.useState(false)

	function calcBlocked() {
		const blockedSenders = senders.filter((e) => e.isBlocked)
		return blockedSenders.length === senders.length
	}

	function calcAllowed() {
		const allowedSenders = senders.filter((e) => e.isAllowed)
		return allowedSenders.length === senders.length
	}

	function calcMailCount() {
		let result = 0
		for (let sender of senders) {
			result += sender.mailCount
		}
		return result
	}

	function calcConnectedEmail() {
		if (senders?.length === 1) return senders[0].connectedEmail

		let result = null
		for (let sender of senders) {
			if (!result) result = sender.connectedEmail
			if (result?.id !== sender.connectedEmail?.id) {
				result = null
				break
			}
		}

		return result
	}

	function calcFilterId() {
		const filteredSenders = senders.filter(sender => sender.hasFilter)
		if (filteredSenders.length !== senders.length) return null

		let result = null
		for (let sender of filteredSenders) {
			if (result && sender.filterId && result !== sender.filterId) return null
			if (!result) result = sender.filterId
		}
		return result
	}

	function calcHasFilter() {
		const filterId = calcFilterId()
		if (!filterId && !senders.some(sender => sender.hasFilter)) return true

		return Boolean(calcFilterId())
	}

	function calcBlockedDomainSetting() {
		return senders.some((e) => e.isEmailBlocked)
	}

	function calcSenderGroup() {
		let id = null
		for (let sender of senders) {
			if (!id && sender.senderGroupId) id = sender.senderGroupId
			if (id && sender.senderGroupId && id !== sender.senderGroupId) {
				id = null
				break
			}
		}
		return id ? senderGroups?.find((e) => e.id === id) : null
	}

	function calcDigests() {
		if (senders.length == 1) {
			setSelectedDigests(senders[0].digests)
		}
	}

	async function reloadSenders() {
		const senderIds = senders.map(e => e.id)
		refreshSenders(senderIds)
	}

	async function loadFilter() {
		const filterId = calcFilterId()
		if (!filterId) return

		const { error, result } = await Filter.getFilter({ id: filterId })
		if (error) {
			setError(error)
			return
		}
		setFilter(result)
	}

	function handleClose(event) {
		event.preventDefault()
		event.stopPropagation()

		if (event) {
			if (DOMHelper.closest(event.target, '.dashboard-mobile-batch-more-settings .page')) {
				return
			}
		}

		onClose(isUpdated)
	}

	async function handleBlock(block) {
		if (senders.length > 1) {
			onBlock(block)
			onClose()
			return
		}
		if (senders[0].hasFilter) {
			setAlert({
				message: errorMessages.FILTER_OVERRIDE_MESSAGE,
				buttons: [
					{
						title: 'Confirm',
						type: 'positive',
						onClick: async () => {

							const { error, results } = await Sender.block(senders, block)
							if (error) {
								setError(error)
								return
							}

							if (ArrayHelper.isValid(results)) {
								reloadSenders()
							}


						}
					}, {
						title: 'Cancel',
						type: 'negative',
						onClick: () => {
							resetAlert()

						}
					},]
			})
			return;
		}

		const { error, results } = await Sender.block(senders, block)

		if (error) {
			setError(error)
			return
		}

		if (ArrayHelper.isValid(results)) {
			reloadSenders()
		}
	}

	function handleViewMails() {
		setMailModal(true)
	}

	async function handleSaveDestinationEmail() {
		if (!connectedEmail?.id) return

		const { error } = await Sender.setConnectedEmail(senders, connectedEmail?.id)
		if (error) {
			setError(error)
			return
		}

		setUpdated(true)
		setPageIndex(PageIndex.Default)

		reloadSenders()
	}

	function handleFilter() {

		if (senders[0].hasFilter) {
			navigate(routeToMe({
				searches: { filterId: true && senders[0].filterId }
			}))
			return
		}

		setFilterModal(true)
	}

	function handleDomainSetting() {
		setDomainSettingsModal(true)
	}

	async function handleSetSenderGroup() {
		if (!senderGroup?.id) return

		const { error } = await Sender.addToGroup(senderGroup?.id, senders)

		if (error) {
			setError(error)
			return
		}

		setUpdated(true)
		setPageIndex(PageIndex.Default)
		reloadSenders()
	}

	function renderFilter() {
		if (!filter) return (senders.length === 1 || !senders.some(sender => sender.hasFilter)) ? 'No filter' : ''
		return filter.fullDescription('<br>')
	}

	function renderGroups() {
		if (senderGroup) return senderGroup.name
		if (senders?.length > 1) return ''
		return 'No group'
	}

	function renderDigests() {
		if (selectedDigests?.length) {
			let result = "";
			selectedDigests.forEach((digestItem, index) => {
				result += `${digestItem.name}${index !== selectedDigests.length - 1 ? ', ' : ''}`
			})
			return result;
		}
		return 'No Digest'
	}

	function checkIsDigestSelected(digestId) {
		if (selectedDigests?.length) {
			let result = false;
			selectedDigests.forEach(item => {
				if (item.id === digestId) {
					result = true;
				}
			})
			return result;
		}
		return false;
	}

	function handleAddNewConnectedEmail() {
		navigate(redirectTo({
			route: routes.Digest,
		}))
	}

	async function onDigestItemClicked(digestId, isSelected) {
		if (senders.length === 1) {
			const senderId = senders[0].id;
			if (!isSelected) {
				Digest.addSenders(digestId, [senderId]);
				const digestItemIndex = myDigests.findIndex(item => {
					if (item.id === digestId) {
						return 1;
					}
					return 0;
				})
				setSelectedDigests([...selectedDigests, myDigests[digestItemIndex]]);
				setPageIndex(PageIndex.Default)
				return;
			}

			const { error, results } = await Digest.getSenders(digestId);
			if (error) {
				return;
			}
			let digestSenderId = undefined;
			results.forEach(senderItem => {
				if (senderItem.senderId === senderId) {
					digestSenderId = senderItem.id;
				}
			})

			Digest.removeSenders([digestSenderId], digestId);
			const localDigests = [...selectedDigests];
			const localDigestItemIndex = selectedDigests.findIndex(item => {
				if (item.id === digestId) {
					return 1;
				}
				return 0;
			})
			localDigests.splice(localDigestItemIndex, 1);
			setSelectedDigests(localDigests);
			setPageIndex(PageIndex.Default)
		}
	}

	return (
		<>
			{(!mailModal && !filterModal && !domainSettingsModal) &&
				<div className="dashboard-mobile-batch-more-settings" data-page={pageIndex} onClick={(event) => handleClose(event)}>
					<div className="page default">
						<div className="page-title">Senders</div>
						<div className={`sender-list ${senderListCollapsed && 'collapsed'}`}>
							{senders.map((sender, index) =>
								<div key={index} className="sender-list-item">
									<Icon type="custom" src={sender.avatar} placeholder={sender.name} />
									<span>{sender.email}</span>
								</div>
							)}
							<button className="expand" onClick={() => setSenderListCollapsed(false)}>
								+ {senders.length - 2} more
							</button>
						</div>

						{/* Allow - Block */}
						<div className="allow-block">
							<button
								className={`allow ${calcAllowed() && 'active'} ${(calcFilterId() || calcBlockedDomainSetting()) && 'disabled'}`}
								// disabled={calcHasFilter() || calcBlockedDomainSetting()}
								onClick={() => handleBlock(false)}>
								<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11.9043 1.44618L5.59614 11.2769L1.7964 7.68701" stroke="#8B8B8B" strokeLinecap="round" />
								</svg>
								<span>Allow</span>
							</button>
							<button
								className={`block ${calcBlocked() && 'active'} ${(calcFilterId() || calcBlockedDomainSetting()) && 'disabled'}`}
								// disabled={calcHasFilter() || calcBlockedDomainSetting()}
								onClick={() => handleBlock(true)}>
								<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11 1.5L6 6.5L11 11.5" stroke="#8B8B8B" strokeLinecap="round" />
									<path d="M1 11.5L6 6.5L1 1.5" stroke="#8B8B8B" strokeLinecap="round" />
								</svg>
								<span>Block</span>
							</button>
						</div>

						{/* Mails */}
						{senders.length === 1 &&
							<div className="page-group">
								<div className="page-group-title">
									<img src={iconMail} alt="" />
									<span>{mailCount} emails received</span>
								</div>
								<div className="page-group-description">
									<span>View all</span>
								</div>
								<button className="view" onClick={() => handleViewMails()}>View</button>
							</div>
						}

						{/* Connected Email */}
						<div className="page-group">
							{connectedEmail &&
								<div className="page-group-title">
									<Icon type="custom" src={connectedEmail.avatar} placeholder={connectedEmail.email} />
									<span>{connectedEmail.email}</span>
								</div>
							}
							<div className="page-group-description">
								<span>Change destination</span>
							</div>
							<button className="view" onClick={() => setPageIndex(PageIndex.DestinationEmail)}>Edit</button>
						</div>

						{/* Filter */}
						{(calcHasFilter()) &&
							<div className="page-group">
								<div className="page-group-title">
									<img src={iconFilter} alt="" />
									<span>Filter</span>
								</div>
								<div className="page-group-description">
									<span dangerouslySetInnerHTML={{ __html: renderFilter() }} />
								</div>
								<button className="view" onClick={() => handleFilter()}>Edit</button>
							</div>
						}

						{/* Domain Settings */}
						{senders.length === 1 &&
							<div className="page-group">
								<div className="page-group-title">
									<img src={iconDomainSetting} alt="" />
									<span>Domains</span>
								</div>
								<div className="page-group-description">
									<span>{senders[0]?.domainSettings?.status || "All Emails & Pop-ups allowed"}</span>
								</div>
								<button className="view" onClick={() => handleDomainSetting()}>View</button>
							</div>
						}

						{/* Groups */}
						<div className="page-group">
							<div className="page-group-title">
								<img src={iconGroup} alt="" />
								<span>Groups</span>
							</div>
							<div className="page-group-description">
								<span>{renderGroups()}</span>
							</div>
							<button className="view" onClick={() => setPageIndex(PageIndex.SenderGroup)}>Edit</button>
						</div>

						<div className="page-group">
							<div className="page-group-title">
								<img src={iconGroup} alt="" />
								<span>Digests</span>
							</div>
							<div className="page-group-description">
								<span>{renderDigests()}</span>
							</div>
							<button className="view" onClick={() => setPageIndex(PageIndex.Digests)}>Edit</button>
						</div>

						{/* Digest */}
						<div className="page-group d-none" onClick={() => setPageIndex(PageIndex.DestinationEmail)}>
							<div className="page-group-title">
								<img src={iconDigest} alt="" />
								<span>Digest</span>
							</div>
							<div className="page-group-description">
								<span>No digest</span>
							</div>
							<button className="view">Add</button>
						</div>
					</div>
					<div className={`page destination-emails ${(pageIndex !== PageIndex.Default && pageIndex !== PageIndex.DestinationEmail) && 'd-none'}`}>
						<div className="page-title"
							onClick={() => {
								setConnectedEmail(calcConnectedEmail())
								setPageIndex(PageIndex.Default)
							}}>
							<span>Change Email Destination</span>
						</div>
						<div className="destination-email-list">
							{connectedEmails.map((e, index) => {
								const selected = connectedEmail?.id === e.id
								return (
									<div
										key={index}
										className={`destination-email ${selected && 'selected'}`}
										onClick={() => setConnectedEmail(e)}>
										<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11.4043 3.82799L5.09614 12.8395L1.2964 9.54875" strokeLinecap="round" />
											<path d="M11.4043 3.82799L5.09614 12.8395L1.2964 9.54875" strokeLinecap="round" />
										</svg>
										<span>{e.email}</span>
									</div>
								)
							})}
						</div>
						<BlueButton
							className="btn-save"
							disabled={connectedEmail?.id === calcConnectedEmail()?.id}
							onClick={() => handleSaveDestinationEmail()}>
							Save
						</BlueButton>
						<div className="description">{"To add new email addresses, please go into Account > Profile"}</div>
					</div>
					<div className={`page sender-group-container ${(pageIndex !== PageIndex.Default && pageIndex !== PageIndex.SenderGroup) && 'd-none'}`}>
						<div className="page-title" onClick={() => setPageIndex(PageIndex.Default)}><span>Add to Group</span></div>
						<div className="sender-group-list">
							{senderGroups.map((e, index) => {
								const selected = senderGroup?.id === e.id
								return (
									<div
										key={index}
										className={`sender-group ${selected && 'selected'}`}
										onClick={() => setSenderGroup(e)}>
										<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11.4043 3.82799L5.09614 12.8395L1.2964 9.54875" strokeLinecap="round" />
											<path d="M11.4043 3.82799L5.09614 12.8395L1.2964 9.54875" strokeLinecap="round" />
										</svg>
										<span>{e.name}</span>
									</div>
								)
							})}
						</div>
						<BlueButton
							className="btn-add-new"
							onClick={() => {
								navigate(redirectTo({
									route: routes.Groups,
								}))
							}}>
							<img style={{ marginRight: 8 }} src={iconAddMore} alt="" loading="lazy" />
							Add New
						</BlueButton>
						<BlueButton
							className="btn-save"
							disabled={senderGroup === calcSenderGroup()?.id}
							onClick={() => handleSetSenderGroup()}>
							Save
						</BlueButton>
					</div>
					<div className={`page sender-group-container ${(pageIndex !== PageIndex.Digests && pageIndex !== PageIndex.Digests) && 'd-none'}`}>
						<div className="page-title" onClick={() => setPageIndex(PageIndex.Default)}><span>Add to Digest</span></div>
						<div className="sender-group-list">
							{myDigests.map((digestItem, index) => {
								if (index < 3) {
									const selected = checkIsDigestSelected(digestItem.id);
									return (
										<div
											key={index}
											className={`sender-group ${selected && 'selected'}`}
											onClick={() => onDigestItemClicked(digestItem.id, selected)}>
											<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M11.4043 3.82799L5.09614 12.8395L1.2964 9.54875" strokeLinecap="round" />
												<path d="M11.4043 3.82799L5.09614 12.8395L1.2964 9.54875" strokeLinecap="round" />
											</svg>
											<span>{digestItem.name}</span>
										</div>
									)
								}
								return null;
							})}
						</div>
						<BlueButton
							className="btn-add-new"
							onClick={() => { handleAddNewConnectedEmail() }}>
							<img style={{ marginRight: 8 }} src={iconAddMore} alt="" loading="lazy" />
							Add New
						</BlueButton>
						<BlueButton
							className="btn-save"
							disabled={senderGroup === calcSenderGroup()?.id}
							onClick={() => handleSetSenderGroup()}>
							Save
						</BlueButton>
					</div>
				</div>
			}
			{mailModal &&
				<MailModal
					sender={senders[0]}
					onClose={() => setMailModal(false)} />
			}
			{filterModal &&
				<FilterModal
					category={Filter.Category.Filter}
					initialSenders={senders}
					onClose={(updated) => {
						setFilterModal(false)
					}}
				/>
			}
			{(domainSettingsModal && senders[0]?.hasDomainSettings) &&
				<DomainSettingsAlert
					value={senders[0]?.domainSettings}
					onClose={(value) => {
						setDomainSettingsModal(false)
						if (value) reloadSenders()
					}} />
			}
			{(domainSettingsModal && !senders[0]?.hasDomainSettings) &&
				<AddDomainModal
					domain={DomainHelper.parseDomain(senders[0]?.email)}
					onClose={(updated) => {
						if (updated) reloadSenders()
						setDomainSettingsModal(false)
					}} />
			}
		</>
	)
}