import React from 'react'

import './styles.scss'

import {
	TextField, Checkbox, BlueButton,
	PublisherConsentAlert,
} from 'components'
import {
	checkPasswordValidation,
	ArrayHelper
} from 'utils'
import { config } from 'constants/config'

import GoogleRecaptcha from 'react-google-recaptcha'

export function PasswordInputEl({
	isRecaptchaPassed,
	setRecaptchaPassed,
	signupLoading,
	isGoogleSignup,
	onNext = (password1, password2, allowConsent) => { }
}) {
	const [password1, setPassword1] = React.useState("")
	const [password2, setPassword2] = React.useState("")
	const [errors, setErrors] = React.useState([])
	const [passwordOneVisibility, setPasswordOneVisibility] = React.useState(true);
	const [passwordTwoVisibility, setPasswordTwoVisibility] = React.useState(true);
	React.useEffect(() => { setErrors([]) }, [password1, password2])

	const [hadReadPrivacyTerms, setReadPrivacyTerms] = React.useState(false)
	const [privacyTermsError, setPrivacyTermsError] = React.useState(false)
	React.useEffect(() => { setPrivacyTermsError(false) }, [hadReadPrivacyTerms])

	const [allowConsent, setAllowConsent] = React.useState(false)
	const [isOpenPublisherConsent, setOpenPublisherConsent] = React.useState(false)

	function checkValidation() {
		let result = true

		const passwordError = checkPasswordValidation(password1, password2)
		if (ArrayHelper.isValid(passwordError) && !isGoogleSignup) {
			setErrors(passwordError)
			result = false
		}

		if (!hadReadPrivacyTerms) {
			setPrivacyTermsError(true)
			result = false
		}

		return result
	}

	function handleNext() {
		if (!isRecaptchaPassed && !isGoogleSignup) return

		if (!checkValidation()) return

		onNext(password1, password2, allowConsent)
	}

	function handlePublisherConsent(value) {
		if (value) setOpenPublisherConsent(true)
		else setAllowConsent(value)
	}

	return (
		<React.Fragment>
			<div className="register-password-input">
				{!isGoogleSignup && <><div className="form">
					<TextField
						type="large"
						id="password"
						autoFocus password
						placeholder="Password"
						passwordVisibility={passwordOneVisibility}
						setPasswordVisibility={setPasswordOneVisibility}
						error={ArrayHelper.isValid(errors)}
						value={password1}
						onChange={(event) => setPassword1(event.target.value)} />
					<TextField
						type="large"
						id="confirmPassword"
						className="form-divider"
						password
						passwordVisibility={passwordTwoVisibility}
						setPasswordVisibility={setPasswordTwoVisibility}
						placeholder="Confirm password"
						error={ArrayHelper.isValid(errors)}
						value={password2}
						onChange={(event) => setPassword2(event.target.value)}
						onNext={() => handleNext()} />
				</div>
				{ArrayHelper.isValid(errors) &&
					errors.map((e, index) => <div key={index} className="error">{e}</div>)
				}
				<div className="description">
					Passwords must have 6 characters, 1 uppercase character, 1 lowercase character, 1 number, 1 non-alphanumeric character.
				</div></>}
				<div className={`group ${privacyTermsError && 'invalid'}`}>
					<Checkbox
						checked={hadReadPrivacyTerms}
						onChange={(value) => setReadPrivacyTerms(!hadReadPrivacyTerms)} />
					<div className="label">
						I agree to lockr's <a href={config.PRIVACY_URL} target="_blank">Privacy</a> and <a href={config.TERMS_URL} target="_blank">Terms</a>.
					</div>
				</div>
				<div className="group">
					<Checkbox
						checked={allowConsent}
						onChange={(value) => handlePublisherConsent(value)} />
					<div className="label">
						I consent to lockr's Publisher Network. <a href={`${config.LOCKR_URL}/tutorial/publisher-consent-network/`} target="_blank">Learn more</a>
					</div>
				</div>
				{!isGoogleSignup && <div className='google-recaptcha-container'>
					<GoogleRecaptcha
						sitekey={process.env.REACT_APP_G_RECAPTCHA_SITE_KEY}
						onChange={value => setRecaptchaPassed(value)} />
				</div>}
			</div>
			<div className="form-button-container">
				<BlueButton
					type="large"
					className="btn-finish"
					disabled={((ArrayHelper.isValid(errors) || !isRecaptchaPassed) && !isGoogleSignup) || signupLoading}
					onClick={() => handleNext()}>
					Finish
				</BlueButton>
			</div>
			{isOpenPublisherConsent &&
				<PublisherConsentAlert
					onOk={() => setAllowConsent(true)}
					onCancel={() => setOpenPublisherConsent(false)} />
			}
		</React.Fragment>
	)
}