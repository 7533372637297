import React, { useRef } from 'react'

import './styles.scss'

import {
	StickyButton,
	PublisherConsentAlert,
	BlueButton,
} from 'components'
import { SectionContainer, SectionItem } from './section'
import { LockrBoxEl } from './lockr-box'
import { DeliveryHoursEl } from './delivery-hours'
import { LockrBoxModalEl } from 'scenes/account/settings/lockr-box/edit-modal'


import extensionBannerDesktop from '../../../assets/images/extension-desktop-accounts.png'
import extensionBannerMobile from '../../../assets/images/extension-mobile-accounts.svg'
import { ExtensionHelper } from 'utils'

import { useAlert, useAuth, LockrBox, Segment, DiscountNewsletterMail } from 'core'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { parseSearchParams, routes } from 'routes'
import { Helmet } from 'react-helmet'
import { DiscountNewsletterEl } from './discount-mail-newsletter'
import { DiscountNewsLetterModalEl } from './discount-mail-newsletter/edit-modal'

export function AccountGeneral({
	className = '',
	onChange = (value) => { },
	onAlert = (alert) => { },
}) {
	const { setAlert, resetAlert } = useAlert()
	const { currentUser, userGetProfile } = useAuth()
	const [isChanged, setChanged] = React.useState(false)
	const [allowGPC, setAllowGPC] = React.useState(false);
	const [allowConsent, setAllowConsent] = React.useState(false)
	const [allowEmailNotification, setAllowEmailNotification] = React.useState(true)
	const [allowDeliveryHours, setAllowDeliveryHours] = React.useState(false)
	const [allowAccountNotification, setAllowAccountNotification] = React.useState(true)
	const [allowDomainNotification, setAllowDomainNotification] = React.useState(false)
	const [allowEmailHeader, setAllowEmailHeader] = React.useState(false)
	const [allowPasswordOption, setAllowPasswordOption] = React.useState(false)
	const [isBlockNewSender, setBlockNewSender] = React.useState(false)
	const [allowLockrBox, setAllowLockrBox] = React.useState(false)
	const [allowDiscountNewsletterMail, setAllowDiscountNewsletterMail] = React.useState(false)

	const [isExtensionInstalled, setIsExtensionInstalled] = React.useState(false);
	const [searchParams] = useSearchParams();
	const [disableEmailTracking, setDisableEmailTracking] = React.useState(false);
	const [lockrPayEnabled, setLockrPayEnabled] = React.useState(false);
	const params = parseSearchParams(searchParams)
	// console.log(params)
	const ref = useRef(null);
	const navigate = useNavigate()

	React.useEffect(() => {
		if (params.scroll == 'gpc') {
			console.log(ref)

			console.log(ref)
			ref?.current?.scrollIntoView()
			navigate('/account/settings')


		}
	}, [])

	/** @type {[LockrBox, Function]} */
	const [lockrBox, setLockrBox] = React.useState(null)
	const [isEditLockrBox, setEditLockrBox] = React.useState(false)

	/** @type {[DiscountNewsletterMail, Function]} */
	const [discountNewsletterMail, setDiscountNewsletterMail] = React.useState(null)
	const [isEditDiscountNewsletter, setEditDiscountNewsletter] = React.useState(false)
	React.useEffect(() => {
		if (allowEmailNotification) {
			setAllowLockrBox(true)
			setAllowDiscountNewsletterMail(true)
		} else {
			setAllowLockrBox(false)
			setAllowDiscountNewsletterMail(false)
		}
	}, [allowEmailNotification])
	React.useEffect(() => {
		if (allowLockrBox || allowDiscountNewsletterMail) {
			if (!allowEmailNotification) {
				setAllowEmailNotification(true)
			}
		}
	}, [allowLockrBox, allowDiscountNewsletterMail])
	const [isOpenPublisherConsent, setOpenPublisherConsent] = React.useState(false)



	React.useEffect(() => {
		setChanged(checkIfChanged())
	}, [allowConsent, allowEmailNotification, allowDeliveryHours, allowAccountNotification, allowDomainNotification, allowEmailHeader, allowPasswordOption, isBlockNewSender, allowLockrBox, allowGPC, disableEmailTracking, lockrPayEnabled, allowDiscountNewsletterMail])


	React.useEffect(() => {
		if (currentUser) {
			setAllowGPC(currentUser.allowGPC)
			setAllowConsent(currentUser.allowConsent)
			setAllowEmailNotification(currentUser.allowEmailNotification)
			setAllowDeliveryHours(currentUser.allowDeliveryHours)
			setAllowAccountNotification(currentUser.allowAccountNotification)
			setAllowDomainNotification(currentUser.allowDomainNotification)
			setAllowEmailHeader(currentUser.allowEmailHeader)
			setAllowPasswordOption(currentUser.allowPasswordOption)
			setBlockNewSender(currentUser.isBlockNewSender)
			setAllowLockrBox(currentUser.allowLockrBox)
			setAllowDiscountNewsletterMail(currentUser.discountNewsletterMail?.allowDiscountNewsletterMail)
			setLockrBox(currentUser.lockrBox ?? LockrBox.default)
			setDisableEmailTracking(currentUser.disableEmailTracking)
			setLockrPayEnabled(currentUser.lockrPayEnabled)
			setDiscountNewsletterMail(currentUser.discountNewsletterMail ?? DiscountNewsletterMail.default)
		}
	}, [currentUser])

	function checkIfChanged() {
		if (!currentUser) return false
		const result = allowConsent !== currentUser.allowConsent
			|| allowEmailNotification !== currentUser.allowEmailNotification
			|| allowDeliveryHours !== currentUser.allowDeliveryHours
			|| allowAccountNotification !== currentUser.allowAccountNotification
			|| allowDomainNotification !== currentUser.allowDomainNotification
			|| allowEmailHeader !== currentUser.allowEmailHeader
			|| allowPasswordOption !== currentUser.allowPasswordOption
			|| isBlockNewSender !== currentUser.isBlockNewSender
			|| allowLockrBox !== currentUser.allowLockrBox
			|| allowGPC !== currentUser.allowGPC
			|| disableEmailTracking !== currentUser.disableEmailTracking
			|| lockrPayEnabled !== currentUser.lockrPayEnabled
			|| allowDiscountNewsletterMail !== currentUser.discountNewsletterMail?.allowDiscountNewsletterMail

		onChange(result)
		return result
	}

	function handleAutoBlock(value) {
		setBlockNewSender(value)
		if (value && (!allowLockrBox || (lockrBox && lockrBox.frequency !== LockrBox.Frequency.Daily))) {
			setAlert({
				message: 'Emails from new Senders will now be blocked by default.<br>We recommend updating your lockrBox settings below to deliver <span style="font-style: italic;">daily</span>&nbsp; so that you catch any important emails in a timely manner.</React.Fragment>',
				buttons: [
					{
						type: 'positive',
						title: 'OK',
						onClick: () => {
							handleAllowLockrBox(true)
							setLockrBox({ ...lockrBox, frequency: LockrBox.Frequency.Daily })
							resetAlert()
						}
					}, {
						type: 'negative',
						title: 'Not Now',
						onClick: () => resetAlert()
					},
				]
			})
		}
	}

	function handleAllowLockrBox(value) {
		setAllowLockrBox(value)
		if (value) setEditLockrBox(true)
	}

	function handleAllowDiscountNewsletterMail(value) {

		setAllowDiscountNewsletterMail(value)
		if (value) setEditDiscountNewsletter(true)
	}

	function handlePublisherConsent(value) {
		if (value) setOpenPublisherConsent(true)
		else setAllowConsent(false)
	}

	async function handleSave() {
		let params = {
			allowConsent,
			allowDeliveryHours,
			allowEmailNotification,
			allowAccountNotification,
			allowDomainNotification,
			allowEmailHeader,
			allowPasswordOption,
			isBlockNewSender,
			allowLockrBox,
			lockrBox,
			allowDeliveryHours,
			allowGPC,
			disableEmailTracking,
			lockrPayEnabled,
			// allowDiscountNewsletterMail,
			discountNewsletterMail: { ...discountNewsletterMail, allowDiscountNewsletterMail }
		}

		const { error } = await currentUser.update(params)
		if (error) {
			onAlert({ message: error })
			return
		}
		else {
			if (allowConsent !== currentUser.allowConsent) {
				if (allowConsent)
					Segment.track(Segment.Event.allowConsentForPublisher, currentUser.id, {})
				else {
					Segment.track(Segment.Event.disableConsentForPublisher, currentUser.id, {})
				}
			}
			if (allowDomainNotification !== currentUser.allowDomainNotification) {
				if (allowDomainNotification)
					Segment.track(Segment.Event.enableChromeExtensionPopup, currentUser.id, {})
				else
					Segment.track(Segment.Event.disableChromeExtensionPopup, currentUser.id, {})
			}
			if (allowEmailNotification !== currentUser.allowEmailNotification) {
				if (allowEmailNotification)
					Segment.track(Segment.Event.enableEmailNotifications, currentUser.id, {})
				else
					Segment.track(Segment.Event.disableEmailNotifications, currentUser.id, {})
			}
			if (isBlockNewSender !== currentUser.isBlockNewSender) {
				if (isBlockNewSender)
					Segment.track(Segment.Event.enableAutoBlock, currentUser.id, {})
				else
					Segment.track(Segment.Event.disableAutoBlock, currentUser.id, {})
			}
			if (allowEmailHeader !== currentUser.allowEmailHeader) {
				if (allowEmailHeader)
					Segment.track(Segment.Event.allowEmailHeader, currentUser.id, {})
				else
					Segment.track(Segment.Event.disableEmailHeader, currentUser.id, {})
			}
			if (allowLockrBox !== currentUser.allowLockrBox) {
				if (allowLockrBox) {
					Segment.track(Segment.Event.enablingLockrBox, currentUser.id, lockrBox)
				}
				else {
					Segment.track(Segment.Event.disablingLockrBox, currentUser.id, lockrBox)
				}
			}
			if (allowDiscountNewsletterMail !== currentUser.allowDiscountNewsletterMail) {
				if (allowDiscountNewsletterMail) {
					Segment.track(Segment.Event.enablingDiscountNewsletterMail, currentUser.id, discountNewsletterMail)
				}
				else {
					Segment.track(Segment.Event.disablingDiscountNewsletterMail, currentUser.id, discountNewsletterMail)
				}
			}
			if (JSON.stringify(lockrBox) !== JSON.stringify(currentUser.lockrBox) && currentUser.lockrBox) {
				Segment.track(Segment.Event.editingLockrBox, currentUser.id, lockrBox)
			}
			if (JSON.stringify(discountNewsletterMail) !== JSON.stringify(currentUser.discountNewsletterMail) && currentUser.discountNewsletterMail) {
				Segment.track(Segment.Event.editingDiscountNewsletterMail, currentUser.id, discountNewsletterMail)
			}
			if (allowPasswordOption !== currentUser.allowPasswordOption) {
				if (allowPasswordOption)
					Segment.track(Segment.Event.enableAccountVerification, currentUser.id, {})
				else
					Segment.track(Segment.Event.disableAccountVerification, currentUser.id, {})
			}
			if (allowDeliveryHours !== currentUser.allowDeliveryHours) {
				if (allowDeliveryHours)
					Segment.track(Segment.Event.enableDeliverHours, currentUser.id, {})
				else
					Segment.track(Segment.Event.disableDeliverHours, currentUser.id, {})

			}
		}

		userGetProfile()
	}

	return (
		<div className={`account-general ${className}`}>
			<Helmet>
				<title>lockrMail Account Settings</title>
			</Helmet>
			<div className="account-general-wrapper">
				<SectionContainer title="Consent">
					<SectionItem
						title="Publisher Network"
						description="Allow lockr to share your information with Publishers."
						imodal={<>
							lockrMail partners with publishers and brands to enable the open and free web.<br />Learn more about our single-sign on integrations with businesses <a href="https://lockrmail.com/tutorial/publisher-consent-network/" target="_blank">here</a>
						</>}
						value={allowConsent}
						onChange={(value) => handlePublisherConsent(value)} />
					<SectionItem
						title="lockrPay"
						description="Coming soon: Earn cash back by enabling lockr to earn a commission from retailers through the lockrMail web app, extension, and emails. Activate to begin earning rewards now."
						imodal={<>
							lockrMail partners with affiliate programs to ensure you get the best deal possible. Learn more how you can use your lockrPay balance or withdraw it  <a href="https://lockrmail.com/tutorial/publisher-consent-network/" target="_blank">here</a>
						</>}
						value={lockrPayEnabled}
						onChange={(value) => setLockrPayEnabled(value)} />
				</SectionContainer>
				<SectionContainer title="Emails">
					<SectionItem
						className="d-none"
						title="Statistics"
						description="Receive weekly updates on blocked emails and account statistics."
						value={allowEmailNotification}
						onChange={(value) => setAllowEmailNotification(value)} />
					<DeliveryHoursEl
						value={allowDeliveryHours}
						onChange={(value) => setAllowDeliveryHours(value)} />
					<SectionItem
						title="Notifications"
						description="Receive updates on new lockrMail features, best practices, and company news."
						value={allowAccountNotification}
						onChange={(value) => setAllowAccountNotification(value)} />
					<SectionItem
						title="Email Wrapper"
						description="Include quick-settings at the top of every delivered message."
						value={allowEmailHeader}
						imodal={<>
							Disable the email header to view email preview text.<br />Learn more about this setting in the <a href="https://lockrmail.com/faq/can-i-disable-the-email-wrapper-header/" target="_blank">Help Center</a>
						</>}
						onChange={(value) => setAllowEmailHeader(value)} />
					<SectionItem
						title="AutoBlock"
						description="Block emails from all new Senders by default."
						value={isBlockNewSender}
						imodal={<>
							Automatically block emails from new Senders.<br />Learn more about this setting in the <a href="https://lockrmail.com/faq/what-does-the-autoblock-setting-do/" target="_blank">Help Center</a>
						</>}
						onChange={(value) => handleAutoBlock(value)} />
					<LockrBoxEl
						value={allowLockrBox}
						onChange={(value) => handleAllowLockrBox(value)}
						onEdit={() => setEditLockrBox(true)} />
					<DiscountNewsletterEl
						value={allowDiscountNewsletterMail}
						onChange={(value) => handleAllowDiscountNewsletterMail(value)}
						onEdit={() => setEditDiscountNewsletter(true)} />
					<SectionItem
						title="Account Verification"
						description="Receive account verification/activation and username/password reset emails regardless of Sender settings."
						value={allowPasswordOption}
						onChange={(value) => setAllowPasswordOption(value)} />
					<SectionItem
						title="Click Tracking"
						description="Allow lockrMail to track email clicks for the purposes of aggregated reporting and data."
						value={!disableEmailTracking}
						onChange={(value) => setDisableEmailTracking(!value)} />
				</SectionContainer>
				<SectionContainer ref={ref} title="Chrome Extension">
					{currentUser?.isExtensionInstalled ?
						<>
							<SectionItem
								title="Global Privacy Control"
								description="Prevent websites from selling or sharing your personal information."
								imodal={<>
									lockr will send a signal to opt out of tracking on sites you visit. Learn more about GPC <a href="https://globalprivacycontrol.org/" target="_blank">here</a>
								</>}
								value={allowGPC}
								onChange={(value) => setAllowGPC(value)} />
							<SectionItem
								title="Pop-ups"
								description="View dynamic notifications as you browse the web."
								imodal={<>
									Disable pop-up notifications from the Chrome extension.<br />Learn more about this setting in the <a href="https://lockrmail.com/faq/how-can-i-disable-the-chrome-extension-pop-up-notifications/" target="_blank">Help Center</a>
								</>}
								value={allowDomainNotification}
								onChange={(value) => setAllowDomainNotification(value)} />
						</>
						: <div className='lockr-chrome-extension'>
							<div className='lockr-chrome-extension-info'>
								<div className='install-text'>Install the Chrome Extension</div>
								<div className='install-info-text'>{screen.width > 737 ? 'Enhance your lockrMail experience.' : 'Enhance your lockrMail desktop experience.'}</div>

								<BlueButton onClick={() => { window.open('https://chrome.google.com/webstore/detail/lockrmail-email-on-your-t/dhpejadloippifpocekdponhfhlmnhgi', '_blank'); }} className='info-button'>Install Now</BlueButton>
							</div>
							<div className='lockr-chrome-extension-info-image'>
								<img src={extensionBannerDesktop}></img>
							</div>
						</div>}
				</SectionContainer>
			</div>
			<StickyButton
				className="btn-save-desktop btn-change-notifications"
				top={0}
				threshold={40}
				disabled={!isChanged}
				onClick={() => handleSave()}>
				Save
			</StickyButton>
			<div className="btn-save-mobile btn-change-notifications">
				<button disabled={!isChanged} onClick={() => handleSave()}>Save</button>
			</div>
			{isEditLockrBox &&
				<LockrBoxModalEl
					lockrBox={lockrBox}
					onChange={(value) => setLockrBox(value)}
					onSave={() => handleSave()}
					onClose={() => setEditLockrBox(false)} />
			}
			{isEditDiscountNewsletter &&
				<DiscountNewsLetterModalEl
					discountNewsletterMail={discountNewsletterMail}
					onChange={(value) => setDiscountNewsletterMail(value)}
					onSave={() => handleSave()}
					onClose={() => setEditDiscountNewsletter(false)} />
			}
			{isOpenPublisherConsent &&
				<PublisherConsentAlert
					onOk={() => setAllowConsent(true)}
					onCancel={() => { }}
					onClose={() => setOpenPublisherConsent(false)} />
			}
		</div>
	)
}