import { plainToClass } from 'class-transformer'

import { Api } from '../../..'
import { DateHelper } from '../../../../utils'

import { AdminAccountState } from './account-state'
import { AdminEmailState } from './email-state'
import { AdminWaitlistState } from './waitlist-state'
import { AdminReferralState } from './referral-state'
import { AdminFilterState } from './filter-state'
import { AdminRevenueDrivesState } from './revenue-drives'

export class AdminTraction {
	accountStats
	emailStats
	waitlistStats
	referralStats
	revenueDriverStats

	static fromJSON(json) {
		if (!json) return null

		const result = plainToClass(AdminTraction, json)
		if (json.accountStats) result.accountStats = AdminAccountState.fromJSON(json.accountStats)
		if (json.emailStats) result.emailStats = AdminEmailState.fromJSON(json.emailStats)
		if (json.waitlistStats) result.waitlistStats = AdminWaitlistState.fromJSON(json.waitlistStats)
		if (json.referralStats) result.referralStats = AdminReferralState.fromJSON(json.referralStats)
		if (json.filterStats) result.filterStats = AdminFilterState.fromJSON(json.filterStats)
		if (json.revenueDriverStats) result.revenueDriverStats = AdminRevenueDrivesState.fromJSON(json.revenueDriverStats)
		return result
	}

	/**
	 * @param {Date} startDate
	 * @param {Date} endDate
	 * @param {"account"|"email"|"referral"|"waitlist"} tractionType
	 */
	static async loadTraction(startDate, endDate, tractionType, rangeType) {
		const defaultError = 'Sorry, there are some troubles to get tractions.'
		let params = {
			startDate: DateHelper.format(startDate, 'YYYY-MM-DD'),
			endDate: DateHelper.format(endDate, 'YYYY-MM-DD'),
		}
		if (tractionType) params = { ...params, tractionType }
		if (rangeType) params = { ...params, rangeType }
		try {
			const { error, data } = await Api.get('/admin/get-traction', params)
			if (error) return { error: defaultError }
			console.log(AdminTraction.fromJSON(data))
			return { result: AdminTraction.fromJSON(data) }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	get statistics() {
		return [this.accountStats, this.emailStats, this.waitlistStats, this.revenueDriverStats]
	}
}

export * from './account-state'
export * from './email-state'
export * from './waitlist-state'
export * from './referral-state'
export * from './filter-state'