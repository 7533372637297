import React, { useState, useEffect } from 'react'
import net from 'net'

import './styles.scss'

import {
    Alert, AlertButton, AlertButtonContainer, AlertPage, AlertTitle, AlertMessage,
    TextField,
} from 'components'

import { AdminWhiteListIP, useAlert } from 'core'
import { isIPAddr } from 'utils'

export function AddWhiteListIPAlert({
    onClose = (updated) => { }
}) {
    const lastInputRef = React.useRef()
    React.useEffect(() => { lastInputRef.current?.focus() }, [lastInputRef.current])
    const [ipAddrs, setIpAddrs] = useState([''])
    const [error, setError] = useState(null)
    const { setAlert } = useAlert()
    useEffect(() => { setError(null) }, [ipAddrs])

    function checkValidation() {
        if (ipAddrs.some(x => !isIPAddr(x))) {
            setError('Invalid IP address.')
            return false
        }

        if (ipAddrs.some((x, i) => ipAddrs.some((y, j) => y === x && i !== j))) {
            setError('Duplicated IP addresses.')
            return false
        }

        return true
    }

    async function handleIpAddr(value, index) {
        setIpAddrs(ipAddrs.map((x, i) => i === index ? value : x))
    }

    function handleAdd() {
        if (!checkValidation()) return

        setIpAddrs([...ipAddrs, ''])
    }

    async function handleSave(isOverride = undefined) {
        if (!checkValidation()) return

        for (const ipAddr of ipAddrs) {
            const { error, errorCode } = await AdminWhiteListIP.create({ ipAddress: ipAddr, isOverride })
            if (error) {
                if (errorCode == 5516) {
                    setAlert({
                        message: 'The IP Address you have entered exists in blocked ip address list. Do you want to override this IP Address?',
                        buttons: [
                            { type: 'positive', label: 'Yes', onClick: () => handleSave(true) },
                            { type: 'negative', label: 'No' },
                        ]
                    });
                    return;
                } else {
                    setError(error)
                    return
                }
            }
        }

        onClose(true)
    }

    return (
        <Alert className="admin-add-whitelist-ip-alert" onCancel={() => onClose()}>
            <AlertPage>
                <AlertTitle value="Add whitelist IP address" />
                {ipAddrs.map((ipAddr, index) =>
                    <div key={index} className="input">
                        <TextField
                            ref={index === ipAddr.length - 1 ? lastInputRef : null}
                            type="normal"
                            placeholder="IP address"
                            value={ipAddr}
                            onChange={event => handleIpAddr(event.target.value.trim(), index)}
                            onNext={() => handleAdd()}
                        />
                    </div>
                )}
                {error && <div className="error">{error}</div>}
                {/* <button className="btn-add" onClick={() => handleAdd()}>Add</button> */}
                <AlertButtonContainer>
                    <AlertButton
                        type="positive"
                        disabled={ipAddrs.some(x => !isIPAddr(x))}
                        onClick={handleSave}>
                        Add
                    </AlertButton>
                </AlertButtonContainer>
            </AlertPage>
        </Alert>
    )
}