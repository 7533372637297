import { Api } from "core/api";
import { BaseModel } from "./base";

export class Shippment extends BaseModel {

    static async getShipmentList(search = '', page = 0, pageSize = 20, completed) {
        try {
            let url = `/orders/list?page=${page}&limit=${pageSize}`;
            if (search && search !== '') url += '&searchText=' + search;
            if (completed) url += '&showCompleted=true';

            const { data, error, success } = await Api.get(url);
            return data
        }
        catch (error) {
            console.log(error)
        }
    }

    static async getShipmentTrackingHistory(shipmentTrackingId) {
        try {
            let url = `/orders/${shipmentTrackingId}/history`;
            const { data, success, error } = await Api.get(url);
            return data
        }
        catch (error) {
            console.log(error)
        }
    }

    static async markAsDelivered(shipmentTrackingId) {
        try {
            let url = `/orders/${shipmentTrackingId}/mark-delivered`;
            const { succes, error } = await Api.put(url);
            return succes
        }
        catch (error) {
            console.log(error);
        }
    }

    static async createShipment(trackingNumber, senderName, senderEmail) {
        try {
            const { success, error } = await Api.post('/orders/add-manual-order', {
                trackingNumber,
                senderName,
                senderEmail,

            })
            return { success, error }
        }
        catch (error) {
            console.log(error)
        }

    }
    static async deleteShipment(shipmentTrackingId) {
        try {
            let url = `/orders/${shipmentTrackingId}`;
            const { success, error } = await Api.delete(url);
            return success;
        }
        catch (error) {
            console.log(error);
        }
    }

    static async createShipment(trackingNumber, senderName, senderEmail) {
        try {
            const { success, error } = await Api.post('/orders/add-manual-order', {
                trackingNumber,
                senderName,
                senderEmail,

            })
            return { success, error }
        }
        catch (error) {
            console.log(error)
        }
    }
}