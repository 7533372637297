import React, { useState } from "react";
import './styles.scss'
import Marquee from "react-fast-marquee";

export function AffiliateComponent({ title = "", description = "", image = "", onClick = () => { }, affiliateObject }) {
    const [imgError, setImgError] = useState(false);
    const handleError = () => {
        setImgError(true);
    };

    return (
        <div className="affiliate-component-parent">
            <>
                {!imgError ? (
                    <img style={{ height: 50, width: 100 }} src={image ?? ""} onError={handleError} />
                ) : (affiliateObject.avatar ? <img style={{ height: 50, width: 100 }} src={affiliateObject.avatar ?? ""} onError={handleError} /> :
                    <div style={{ height: 50, width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ height: 50, width: 50, backgroundColor: 'black', color: 'white', fontWeight: 'bold', borderRadius: 100, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{title.split(" ")[2].charAt(0).toLocaleUpperCase()}</div></div>
                )}
            </>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                <div className="title">{title}</div>
                <div className="description" dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
            <div className="shop-now" onClick={onClick}>
                Shop Now
            </div>
        </div>
    )
}