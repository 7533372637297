import { Api } from 'core/api'
import { errorMessages } from 'constants/error'
import moment from 'moment-timezone'

const Analytics = require('analytics-node')
const analytics = new Analytics(process.env.REACT_APP_SEGMENT_WRITE_KEY)

export class Segment {
	static Type = Object.freeze({
		Discover: 'discover',
		HelpCenter: 'help-center',
		TFA: '2fa'
	})

	static Event = Object.freeze({
		Login: 'Login',
		accessingDiscover: 'Accessing Discover',
		accessingSupport: 'Accessing Support',
		referringFriend: 'Referring a friend',
		userUpdatedProfile: 'User updated profile',
		userEditedFilter: 'User Edited filter',
		userCreatedFilter: 'User Created filter',
		userDeletedFilter: 'User Deleted filter',
		creatingGroup: 'Creating a group',
		editingGroup: 'Editing a group',
		deletingGroup: 'Deleting a group',
		addingSenderToGroup: 'Adding a sender to a group',
		userAllowedSender: 'User Allowed Sender',
		userBlockedSender: 'User Blocked Sender',
		userDisabled2fa: 'User Diabled 2FA',
		userEnabled2fa: 'User enabled 2FA',
		creatingDigest: 'Creating a Digest',
		deletingDigest: 'Deleting a Digest',
		editingDigest: 'Editing delivery settings on a Digest',
		addingSenderInDigest: 'Adding a sender to a Digest',
		removingSenderDigest: 'Removing a Sender from a Digest',
		removingSenderFromGroup: 'Removing a Sender from a group',
		manuallyDeliveringEmail: 'Manually delivering an email from a Sender',
		changeDestinationEmailForSender: 'Change destination email for a Sender',
		addNewDestinationEmail: 'Adding additional destination email',
		removeDestinationEmail: 'Removing a destination email',
		allowConsentForPublisher: 'Enabling consent for publisher network',
		disableConsentForPublisher: 'Disabling consent for publisher network',
		disableChromeExtensionPopup: 'Disabling chrome extension pop-ups',
		enableChromeExtensionPopup: 'Re-enabling chrome extension pop-ups',
		disableEmailNotifications: 'Disabling notifications',
		enableEmailNotifications: 'Re-enabling notifications',
		enableAutoBlock: 'Enabling auto-block',
		disableAutoBlock: 'Disabling auto-block',
		allowEmailHeader: 'Re-enabling email wrapper',
		disableEmailHeader: 'Disabling email wrapper',
		disablingLockrBox: 'Disabling lockrBox',
		enablingLockrBox: 'Re-enabling lockrBox',
		editingLockrBox: 'Editing delivery settings for lockrBox',
		enableAccountVerification: 'Re-enabling account verification',
		disableAccountVerification: 'Disabling account verifications',
		enableDeliverHours: 'Enabling delivery hours',
		disableDeliverHours: 'Removing delivery hours',
		editingeliveryHours: 'Editing delivery hours',
		openingEmailModule: 'Opening email module for a Sender',
		removingDomainBlockForEmails: 'Removing a domain block for emails',
		removingDomainBlockForPopup: 'Removing a domain block for pop-ups',
		editingDomainBlockForEmails: 'Editing a domain block for emails',
		editingDomainBlockForPopup: 'Editing a domain block for pop-ups',
		enablingAccountPasswordUpdatesBySender: 'Editing account/password update emails by Sender',
		editingConsentBySender: 'Editing consent by Sender',
		disablingDiscountNewsletterMail: 'Disabling DiscountNewsletterMail',
		enablingDiscountNewsletterMail: 'Re-enabling DiscountNewsletterMail',
		editingDiscountNewsletterMail: 'Editing delivery settings for DiscountNewsletterMail',
		visitMyOrdersPage: 'Visiting My Orders Page',
		markShipmentDelivered: 'Mark Shipment Delivered',
		clickOnShipmentMap: 'MAP_ENGAGEMENT',
		reportDiscount: 'Report Discount'
	})

	static identify(userId, name, email, user) {
		analytics.identify({
			userId: user?.id,
			traits: {
				"lockr-User-Id": user?.id,
				'name': name,
				'email': user?.primaryEmail?.email,
				'lockrMail': email,
				"primaryEmail": user?.primaryEmail?.email,
				'lockrBox': user?.lockrBox,
				"registraionDate": moment(user?.createdAt).format("MM/DD/YYYY"),
				"lastSignin": moment(user?.lastLoginAt).format("MM/DD/YYYY"),
				"userTags": user?.tags ? user.tags.join('-') : '',
				"phone": user?.phone,
				"isExtensionInstalled": user?.isExtensionInstalled,
				"link": user?.permalink,
			}
		})
	}

	static track(event, userId, params = {}, currentUser) {
		console.log(params)
		if (userId) {
			analytics.track({
				event,
				userId,
				properties: params,

			})
		}
	}

	static async submit(type) {
		try {
			const { error } = await Api.post('/segement', { type })
			return { error: error && errorMessages.CONTACT_US }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}
}