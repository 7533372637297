import React from "react"
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useWindowSize } from 'react-use'

import './styles.scss'
import iconClose from "assets/images/onboard_close.svg"
import iconLock from "assets/images/onboard_lock.svg"

import {
	TextField, BlueButton, LockrScanPreview,
} from "components"

import {
	useAlert, User, useAuth,
	useStorage,
	Segment,
} from 'core'
import {
	isEmail, isValidText, isLockrMail,
	Storage,
} from "utils"
import { errorCodes, errorMessages, config } from '../../constants'
import { routes, redirectTo, parseSearchParams } from 'routes'
import moment from "moment-timezone"
import googleSigninButton from '../../assets/images/google_button.png'
import googleButton from '../../assets/images/google_logo.png'


const popupWidth = 605;
const popupHeight = 900;
const popupLeft = (screen.width - popupWidth) / 2;
const popupTop = (screen.height - popupHeight) / 2;


export function LoginScene() {
	const location = useLocation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const { width: windowWidth } = useWindowSize()

	const { setError: setGlobalError, setErrorCode } = useAlert()
	const { currentUser, userGetProfile, currentLockrScanUser } = useAuth()

	const [email, setEmail] = React.useState("")
	const [password, setPassword] = React.useState("")
	const [errorType, setErrorType] = React.useState("")
	const [error, setError] = React.useState(null)
	const [passwordVisibility, setPasswordVisibility] = React.useState(true)

    const [ipAddr, setIpAddr] = React.useState("");

	const [lockrScanPreview, setLockrScanPreview] = React.useState(false)
	React.useEffect(() => {
		setErrorType('')
		setError(null)
	}, [email, password])

	React.useEffect(() => {
		setLockrScanPreview(location.state?.lockrScanPreview)
		redirect(currentUser)
	}, [currentUser])

    React.useEffect(() => {
        window.addEventListener("message", handleUserLogin)
        return (() => {
            window.removeEventListener("message", handleUserLogin);
        })
    }, [])

	React.useEffect(() => {
        getIpAddr();
    }, [])

	const handleUserLogin = (event) => {
		const data = event.data;
		if (data?.error) {
			if (typeof data?.error === 'object') {
				setError(JSON.stringify(data.error))
			} else {
				if(data.errorCode === errorCodes.REQUIRED_VERIFY_PRIMARY_EMAIL){
					setError(errorMessages.REQUIRED_VERIFY_PRIMARY_EMAIL)
				} else if (data.errorCode === errorCodes.BLOCKED_ACCOUNT) {
					setGlobalError(errorMessages.BLOCKED_ACCOUNT)
				} else if (data.errorCode === errorCodes.DELETED_BEFORE) {
					setError(data.error)
				} else if(data.errorCode === errorCodes.LOGIN_ATTEMPT_REACHED){
					setError(errorMessages.LOGIN_ATTEMPT_REACHED)
				}else {
					setError(data.error)
				}
			}
			return
		} else if(!data.personalEmail && !data.accessToken) return
		
		if(!data.accessToken){
			return navigate(redirectTo({ route: routes.Register }), { state: { googleSignupData: data } })
		}
		Storage.accessToken = data.accessToken
		Storage.refreshToken = data.refreshToken
		Storage.cookieAccessToken = data.accessToken
		Storage.cookieRefreshToken = data.refreshToken

		
		if (data?.id) {
			Segment.track(Segment.Event.Login, data?.id, { dateTime: moment().format("MM/DD/YYYY hh:mm"), email: data?.email, "isSignInWithGoogle": true }, currentUser)
		}
		userGetProfile()
		if(currentLockrScanUser){
			navigate(routes.LockrScanDashboard, { state: { lockrScanConnectionUnsuccessfulAlert: !data?.lockrScanConnectionSuccessful } });
		}
    }

    const getIpAddr = async () => {
		const response = await fetch('https://api.ipify.org?format=json');
		const data = await response.json();
        setIpAddr(data?.ip);
    }

	/** @param {User} user */
	function redirect(user) {
		if (!user) return

		if (user.allow2FA && !user.is2FAPassed) {
			navigate(redirectTo({ route: routes.TFA }), { state: { from: location.state?.from } })
			return
		}

		if (location.state?.from) {
			navigate(location.state?.from)
		} else if (user.isAdmin) {
			navigate(redirectTo({ route: routes.Admin }))
		} else if (user.doneTutorial) {
			navigate(redirectTo({ route: routes.Dashboard }))
		} else {
			navigate(redirectTo({ route: routes.Tutorial }))
		}
	}

	React.useEffect(() => { loadInfoFromUrl() }, [searchParams])
	function loadInfoFromUrl() {
		const { lockrMail } = parseSearchParams(searchParams)
		if (lockrMail) setEmail(lockrMail)
	}

	function handleForgotUsername() {
		navigate(redirectTo({
			route: routes.ForgotUsername,
		}))
	}

	function handleForgotPassword() {
		navigate(redirectTo({ route: routes.ForgotPassword }), { state: { email } })
	}

	async function handleLogin() {
		if (!isEmail(email)) {
			setErrorType('email')
			setError('Email you input is invalid. Please confirm it is formatted properly.')
			return
		}

		if (!isLockrMail(email)) {
			setErrorType('email')
			setError('The email address entered is not a valid lockrMail account.')
			return
		}

		if (currentLockrScanUser) {
			const { error, errorCode, result } = await User.lockrScanToLockrMailLogin(email, password)
			if (error) {
				if (errorCode === 5555) { // redirect to PROD if tries with PROD account on DEV
					window.open(config.PROD_URL, "_self")
				}
				else if (errorCode === errorCodes.LOGIN_ATTEMPT_REACHED) {
					setErrorType('email|password');
					setError(errorMessages.LOGIN_ATTEMPT_REACHED);
				}
				else if (errorCode === errorCodes.AUTH_FAILED) {
					setErrorType('email|password')
					setError(errorMessages.INCORRECT_USERNAME_OR_PASSWORD)
				} else if (errorCode === errorCodes.REQUIRED_VERIFY_PRIMARY_EMAIL) {
					setError(`Error: ${errorMessages.REQUIRED_VERIFY_PRIMARY_EMAIL}`)
				} else if (typeof error === 'string' && error.toLowerCase().includes('email')) {
					setErrorType('email')
					setError('The email address entered is not a valid lockrMail account.')
				} else if (typeof error === 'string' && error.toLowerCase().includes('password')) {
					setErrorType('password')
					setError('The password you entered is incorrect. Try again, or reset your password above.')
				} else {
					setGlobalError(error)
				}
				return
			}

			if (result?.id) {
				Segment.track(Segment.Event.Login, result?.id, { dateTime: moment().format("MM/DD/YYYY hh:mm"), email: email, "isSignInWithGoogle": false }, currentUser)
			}

			userGetProfile()
			navigate(routes.LockrScanDashboard, { state: { lockrScanConnectionUnsuccessfulAlert: !result?.lockrScanConnectionSuccessful } });
		}
		else {
			const { error, errorCode, result } = await User.login(email, password)
			if (error) {
				if (errorCode === 5555) { // redirect to PROD if tries with PROD account on DEV
					window.open(config.PROD_URL, "_self")
				}
				else if (errorCode === errorCodes.LOGIN_ATTEMPT_REACHED) {
					setErrorType('email|password');
					setError(errorMessages.LOGIN_ATTEMPT_REACHED);
				} else if (errorCode === errorCodes.CONFIGURED_TO_SIGN_IN_WITH_GOOGLE) {
					setErrorCode(errorCodes.CONFIGURED_TO_SIGN_IN_WITH_GOOGLE)
					setGlobalError(errorMessages.CONFIGURED_TO_SIGN_IN_WITH_GOOGLE)
				}
				else if (errorCode === errorCodes.AUTH_FAILED) {
					setErrorType('email|password')
					setError(errorMessages.INCORRECT_USERNAME_OR_PASSWORD)
				} else if (errorCode === errorCodes.REQUIRED_VERIFY_PRIMARY_EMAIL) {
					setError(`Error: ${errorMessages.REQUIRED_VERIFY_PRIMARY_EMAIL}`)
				} else if (typeof error === 'string' && error.toLowerCase().includes('email')) {
					setErrorType('email')
					setError('The email address entered is not a valid lockrMail account.')
				} else if (typeof error === 'string' && error.toLowerCase().includes('password')) {
					setErrorType('password')
					setError('The password you entered is incorrect. Try again, or reset your password above.')
				} else {
					setGlobalError(error)
				}
				return
			}

			if (result?.id) {
				Segment.track(Segment.Event.Login, result?.id, { dateTime: moment().format("MM/DD/YYYY hh:mm"), email: email, "isSignInWithGoogle": false }, currentUser)
			}

			userGetProfile()
		}
	}

	function handleSignup() {
		navigate(redirectTo({ route: routes.Register }))
	}

	return (
		<div id="parent" className="page-container login">
			<div className="wrapper">
				<img className="logo" src={iconLock} alt="Loc.kr" />
				<div className='title'>Welcome back</div>
				<div className="form">
					<input type="hidden" id="gclid_field" name="gclid_field" value=""></input>
					<TextField
						type="large"
						id="email"
						autoFocus
						autoComplete domains={[config.REACT_APP_MAIL_ENVIRONMENT]}
						error={errorType.includes('email')}
						placeholder={(windowWidth >= 375) ? "lockrMail email address" : "Email address"} value={email}
						onChange={event => setEmail(event.target.value.trim())}
						buttonTitle="Forgot?" isButtonShown onButtonClicked={() => handleForgotUsername()} />
					<TextField
						type="large"
						id="password"
						className="form-divider"
						password
						passwordVisibility={passwordVisibility}
						setPasswordVisibility={setPasswordVisibility}
						error={errorType.includes('password')}
						placeholder="Password" value={password}
						onChange={event => setPassword(event.target.value)}
						onNext={handleLogin}
						buttonTitle="Forgot?" isButtonShown onButtonClicked={() => handleForgotPassword()} />
				</div>
				<div className="error" dangerouslySetInnerHTML={{ __html: error }}>
					{/* {error && error} */}
				</div>
				<div className="button-container">
					<BlueButton
						type="large"
						className="btn-login"
						disabled={!isValidText(email) || !isValidText(password)}
						onClick={() => handleLogin()}>
						Log in
					</BlueButton>
				</div>
				
				<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='or-btn'>
					<span 
						style={{
							fontWeight: 'bold',
							color: '#605F64',
							whiteSpace: 'nowrap',
						}}
					>
						or
					</span>
				</div>
				<div style={{marginTop: 10}}>
					<div
						className='google-button'
						onClick={async () => {
							setError('');
							if (Storage.accessToken) {
								window.open(config.GOOGLE_AUTH_URL + `&state=${ipAddr}^${Storage.accessToken}`, 'popUpWindow',
									'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
								);
							}
							else {
								window.open(config.GOOGLE_AUTH_URL + `&state=${ipAddr}`, 'popUpWindow',
									'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
							}
						}}
					>
						<img className="google-button-img" src={googleButton}></img>
						<span className='google-button-text'>Sign in with Google</span>
					</div>
				</div>
				<div className="option">
					<span>Don&#39;t have a login?</span>
					<a onClick={handleSignup}>Create an account</a>
				</div>
			</div>
			<a className="close" href="https://lockrmail.com">
				<img src={iconClose} alt="" />
			</a>
			{lockrScanPreview && <LockrScanPreview login={true} onClose={() => { setLockrScanPreview(false) }}></LockrScanPreview>}
		</div>
	)
}
