import './styles.scss';
import React, { useState } from 'react';
import copyImg from '../../../assets/images/copy-icon-white.svg'
import { checkUrl, isUrl } from 'utils';
import moment from 'moment-timezone';
import lockrLogo from '../../../assets/images/lockr-logo-small.svg'
import gmailLogo from '../../../assets/images/google-gmail-logo-small.svg'
import { Typography } from 'antd';
import stack from '../../../assets/images/stack.svg'
import dotsMenu from '../../../assets/images/dots_menu.svg'
import { SelectDropDown, SelectOption } from 'components';
import emailIcon from '../../../assets/images/email_icon_white_bordered.svg';
import resendIcon from '../../../assets/images/resend_icon_white_bordered.svg';
import reportIcon from '../../../assets/images/warning_icon_white_bordered.svg'
import { Mail, Segment, useAuth } from 'core';
import { Discount } from 'core/models/discount';
import externalIcon from 'assets/images/external-arrow.svg'
import externalIconBlack from 'assets/images/external-arrow-black.svg'
import discountTag from '../../../assets/images/discount-tag-tick.svg'

export function DiscountComponent({ discountObj, setDomain, removeDiscountFromList, openToast, replaceDomainObjOnIndex, index, height, fromShippment = false }) {

    const [loading, setLoading] = useState(false);
    const [discountPopover, setDiscountPopover] = useState(false)

    const [otherOffersVisible, setOtherOffersVisible] = useState(false);
    const { currentUser } = useAuth()

    async function resendMail() {
        if (!discountObj.messageId) return
        const { error } = await Mail.deliver([discountObj.messageId])
        if (error) {
            return
        }
    }

    async function reportMail() {

        setLoading(true);
        const { success, error } = await Discount.reportCoupon(discountObj.id);
        if (currentUser.id) {
            Segment.track(Segment.Event.reportDiscount, currentUser.id, { discountId: discountObj.id })
        }
        if (discountObj?.count && discountObj?.count > 1) {
            const { success, error, data } = await Discount.getStackCoupon(discountObj?.senderDomain);
            if (success)
                replaceDomainObjOnIndex(index, data)
        }
        else
            removeDiscountFromList(discountObj.id);
        openToast();
        if (success) {
            setLoading(false);
            return;
        }
        setLoading(false);
    }


    function getExpiryDate() {
        let date = moment(discountObj?.codeExpiry).format('MM/DD/YYYY');
        if (date.charAt(0) == '0') {
            return date.slice(0, 0) + date.slice(1);
        }
        return date
    }

    function showPopover() {
        setDiscountPopover(true);

        setTimeout(() => setDiscountPopover(false), 5000)
    }

    return (
        <div onMouseLeave={() => {
            setOtherOffersVisible(false)
        }} onMouseEnter={() => { discountObj?.count > 1 && setOtherOffersVisible(true) }} className='discount-component' style={{ opacity: loading && 0.4, height: fromShippment && 173, display: fromShippment && 'inline-block', width: fromShippment && '100%' }}>
            {(!fromShippment) && discountObj?.count > 1 && otherOffersVisible && <div onClick={() => { setDomain(discountObj.senderDomain) }} className='discount-stack-count'>
                View all
            </div>}
            {(!fromShippment) && discountObj?.count > 1 && !otherOffersVisible && <div onClick={() => { setDomain(discountObj.senderDomain) }} className='discount-stack-count-view-all'>
                {discountObj.count} Discounts
            </div>}
            {/* {otherOffersVisible && <div onClick={() => { setDomain(discountObj.senderDomain) }} className='discount-see-other-offers'>
                <img src={discountTag}></img>
                See Other Offers
            </div>} */}

            <div className='discount-avatar' style={{ marginLeft: fromShippment && 'calc(50% - 38px)' }}>
                {discountObj.avatar ? <img style={{ height: 40, width: 40 }} src={discountObj.avatar}></img> : discountObj?.senderDomain?.charAt(0)}
            </div>
            <div className='discount-type-avatar'>

                {discountObj.type == 'lockrMail' ? <img src={lockrLogo}></img> : <img src={gmailLogo}></img>}
                {!fromShippment && <span style={{ height: 16, cursor: 'pointer' }}><img style={{ height: 16 }} src={dotsMenu}></img>
                    <SelectDropDown mobileHeader={"More Options"}>
                        {discountObj.type == 'lockrScan' && <SelectOption className={'dots-menu-options'} selectable={false} onClick={() => {
                            window.open(
                                `https://mail.google.com/#search/from:${discountObj?.senderEmail} in:anywhere`, "_blank");
                        }}>< img src={emailIcon} style={{ marginRight: 10 }} />View email</SelectOption>}
                        {discountObj.type == 'lockrMail' && <SelectOption className={'dots-menu-options'} selectable={false} onClick={() => resendMail()}><img src={resendIcon} style={{ marginRight: 10 }} />Resend email</SelectOption>}
                        <SelectOption selectable={false} className={'dots-menu-options'} onClick={() => reportMail()}><img src={reportIcon} style={{ marginRight: 10 }} />Report code</SelectOption>
                        {discountObj?.count > 1 && <SelectOption selectable={false} className={'dots-menu-options'} onClick={() => setDomain(discountObj.senderDomain)}><img src={discountTag} style={{ marginRight: 10 }} />See other offers</SelectOption>}
                    </SelectDropDown></span>}
            </div>
            <div className='discount-brand-name'>
                {discountObj?.senderDomain?.split('.')[discountObj?.senderDomain?.split('.')?.length - 2]}
                <img onClick={() => {
                    navigator.clipboard.writeText(discountObj.code)
                    window.open(`https://${discountObj?.senderDomain}`, '_blank');

                }} style={{ marginLeft: 8, height: 10, width: 10, cursor: 'pointer' }} src={externalIcon}></img>
            </div>
            <div className='discount-description' style={{ maxWidth: fromShippment && '100%' }}>
                {discountObj.shortDescription} &nbsp;
            </div>
            {checkUrl(discountObj.code) ?
                <div className='discount-code-activate' onClick={() => { window.open(discountObj?.code, '_blank') }}>
                    Activate Now
                </div> :
                <div className={`discount-code ${!discountPopover ? 'discount-code-border' : ''}`}>
                    <div className='discount-code-text' style={{ maxWidth: fromShippment && '100%' }}>
                        {discountObj.code}
                    </div>
                    <div style={{ cursor: 'pointer' }} className='discount-copy-image'>
                        <Typography.Text
                            copyable={{
                                icon: [<img style={{ height: 16, width: 16 }} src={copyImg}></img>],
                                tooltips: false,
                                text: discountObj.code,
                                onCopy: () => showPopover()
                            }}
                            id={`copy-${index}`}
                        ></Typography.Text>
                    </div>

                    <div
                        className={`discount-code-popover ${discountPopover ? 'discount-code-popover-animate' : ''}`}
                        onClick={() => {
                            navigator.clipboard.writeText(discountObj.code)
                            window.open(`https://${discountObj?.senderDomain}`, '_blank');
                        }}
                    >
                        <p> Visit Website </p>
                        <img
                            src={externalIconBlack}
                        ></img>
                    </div>
                </div>}
            <div className='expiry'>
                {discountObj?.codeExpiry && getExpiryDate()} &nbsp;

            </div>
            {!fromShippment && discountObj.count > 1 && <div className='stack-image'>
                <img src={stack}></img>
            </div>}

        </div>)
}