require('dotenv').config()

export class config {
	static get isChrome() { return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) }

	static get hostname() {
		const result = window && window.location && window.location.hostname
		if (!result || result === '') {
			console.log('hostname is not ready.')
		}
		return result
	}

	static PROD_URL = `https://${process.env.REACT_APP_PROD_HOST}/`
	
	static GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GMAIL_CLIENT_ID_FOR_SIGNUP}&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_API_BASE_URL}/login/googleCallback`)}&response_type=code&scope=${encodeURIComponent("https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email")}&access_type=offline&prompt=consent`

	static get IS_LOCAL() { return !config.IS_DEV && !config.IS_STAGING && !config.IS_PROD }
	static get IS_DEV() { return config.hostname === process.env.REACT_APP_DEV_HOST }
	static get IS_STAGING() { return config.hostname === process.env.REACT_APP_STAGING_HOST }
	static get IS_PROD() { return config.hostname === process.env.REACT_APP_PROD_HOST }

	static get API_BASE_URL() { return process.env.REACT_APP_API_BASE_URL }
	static get RSA_PUBLIC_KEY() { return process.env.REACT_APP_RSA_KEY }

	static get LOCKR_URL() { return process.env.REACT_APP_WORDPRESS_URL }

	static get PRIVACY_URL() { return config.LOCKR_URL + '/privacy-policy' }
	static get CONTACT_URL() { return config.LOCKR_URL + '/contact' }
	static get TERMS_URL() { return config.LOCKR_URL + '/terms-of-service' }
	static get HELP_CENTER_URL() { return config.LOCKR_URL + '/help-center' }
	static get SUPPORT_URL() { return config.LOCKR_URL + '/support' }
	static get FEEDBACK_URL() { return 'https://lockr.canny.io/' }

	static get STATUS_URL() { return process.env.REACT_APP_STATUS_URL }

	static get CRX_URL() { return process.env.REACT_APP_CHROME_EXTENSION_URL }

	static get GOOGLE_MAP_API_KEY() { return process.env.GOOGLE_MAPS_API_KEY }

	static get REACT_APP_MAIL_ENVIRONMENT() { return process.env.REACT_APP_MAIL_ENVIRONMENT }

}